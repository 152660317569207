
import React, { useState, createContext, useEffect, useContext } from "react"
import axios from 'axios'
import useFetch from "utils/useFetch"
import useTokens from 'utils/useTokens'
import { updateCohortKeys } from "utils/definitions"
import { pick } from 'utils/pick'
import config from '../config';
import { AuthContext } from "context/authContext"

const defaultContext = {
    users: [],
    cohorts: [],
    error: null,
    success: null,
    cohortsDropDown: [],
    loading: false,
    getUsers: () => {
        throw new Error('getUsers() not implemented')
    },
    updateUsers: () => {
        throw new Error('updateUsers() not implemented')
    },
    getCohorts: () => {
        throw new Error('getCohorts() not implemented')
    },
    handleUpdateCohort: () => {
        throw new Error('handleUpdateCohort() not implemented')
    },
    sendWelcomeEmail: () => {
        throw new Error('sendWelcomeEmail() not implemented')
    },
}

const makeCohortDropDown = (cohorts) => {
    return cohorts.map(cohort => {
        return {
            value: cohort.start_date, label: new Date(cohort.start_date).toLocaleDateString(
                'en-gb',
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }
            )
        }
    })

}

export const AdminContext = createContext(defaultContext)

export const AdminProvider = ({
    children
}) => {

    const { user, getUser } = useContext(AuthContext)


    const [students, setStudents] = useState()
    const [alumni, setAlumni] = useState();
    const [staff, setStaff] = useState();

    const [cohorts, setCohorts] = useState()
    const [cohort, setCohort] = useState()

    const [cohortsDropDown, setCohortsDropDown] = useState()

    const [error, setError] = useState()

    const [success, setSuccess] = useState()

    const [loading, setLoading] = useState()


    useEffect(() => {
        if (cohorts)
            setCohortsDropDown(makeCohortDropDown(cohorts))
    }, [cohorts])
    useEffect(() => {
        const timer = setTimeout(() => {
            setError()
        }, 5000);

        return () => clearTimeout(timer);
    }, [error]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess()
        }, 5000);

        return () => clearTimeout(timer);
    }, [success]);

    const { fetchData } = useFetch()
    const { getTokens } = useTokens()

    const getCohorts = async () => {
        const sort = 'sort=-start_date'
        const cohorts = await fetchLists('cohorts', sort)
        const mapC = cohorts.map(cohort => { return { ...cohort, count: cohort.users.length } })
        setCohorts(mapC)
    }

    const getUsers = async (cablifecycle) => {

        const sort = 'sort=-start_date'
        const filter = cablifecycle ? `&cablifecycle=${cablifecycle}` : ''

        let url = user.role === 'admin' ? 'users' : 'users/students'

        const users = await fetchLists(url, sort, filter)
        switch (cablifecycle) {
            case 'Client':
                setStudents(users)
                break;
            case 'Alumni':
                setAlumni(users)
                break;
            case 'Staff':
                setStaff(users)
                break;

            default:
                break;
        }
    }
    const sendWelcomeEmail = async (cohortId) => {
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        setLoading(true)


        if (token) {
            setLoading(true)
            try {
                // console.log('Date(tokens.access.expires) :>> ', Date(tokens.access.expires));
                // if (Date(tokens.access.expires) < Date.now()) {
                const res = await fetchData(
                    `${config.serverURL}/api/cohorts/welcome/${cohortId}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )


                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {

                        const data = await res.json()
                        setSuccess('Email sent')
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }
    const getHubspotNewStudents = async () => {
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            setLoading(true)

            try {
                // console.log('Date(tokens.access.expires) :>> ', Date(tokens.access.expires));
                // if (Date(tokens.access.expires) < Date.now()) {
                const res = await fetchData(
                    `${config.serverURL}/api/hubspot`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )


                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {

                        const data = await res.json()
                        setSuccess('Students from Hubspot added')
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    const fetchLists = async (type, sort = '', filter = '') => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            setLoading(true)

            try {
                // console.log('Date(tokens.access.expires) :>> ', Date(tokens.access.expires));
                // if (Date(tokens.access.expires) < Date.now()) {
                const res = await fetchData(
                    `${config.serverURL}/api/${type}?${sort}${filter}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )


                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        return data
                        // setUsers(users.results)


                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    const uploadFile = async (file, type, id) => {
        setLoading(true)
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        const data = new FormData();
        data.append('file', file);
        data.append('filename', 'img');

        try {
            const response = await axios.post(`${config.serverURL}/api/${type}/upload/${id}`, data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (response) {
                setLoading(false)

                return response.data
            }
        }
        catch (error) {
            console.log(error);
            setError('Error uploading the image')
            setLoading(false)
            return ''
        };
    }
    const deleteFile = async (type, id) => {
        setLoading(true)
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        if (token) {
            try {
                const response = await axios.post(`${config.serverURL}/api/${type}/delete/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                if (response) {
                    setLoading(false)
                    if (type === 'users') getUser()
                    if (type === 'cohorts') getCohort(id)
                }
            }

            catch (error) {
                console.log(error);
                setLoading(false)
            };
        }
    }

    const handleUpdateCohort = async (cohortId, state) => {
        console.log('cohortId :>> ', cohortId);
        setLoading(true)
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const urlencoded = new URLSearchParams();

            const body = pick(state, updateCohortKeys()[user.role]);
            console.log('body :>> ', body);
            Object.keys(body).forEach(key => urlencoded.append(key, state[key]))
            const tokenObj = await getTokens()
            const token = tokenObj?.access?.token


            if (token) {



                const res = await fetchData(
                    `${config.serverURL}/api/cohorts/${cohortId}`,
                    "PATCH",
                    urlencoded,
                    true,
                    {},
                    token
                )

                if (res.error) setError(res.error.message)
                else {
                    const json = await res.json()
                    setLoading(false)
                    if (res.ok) {

                        getCohort(cohortId)
                        setSuccess('Cohort update successful')
                    }
                    else setError(json.message)
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
    const addStudent = async (cohortId, studentId) => {

        setLoading(true)
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const urlencoded = new URLSearchParams();
            urlencoded.append('studentId', studentId)

            const tokenObj = await getTokens()
            const token = tokenObj?.access?.token


            if (token) {



                const res = await fetchData(
                    `${config.serverURL}/api/cohorts/addStudent/${cohortId}`,
                    "PATCH",
                    urlencoded,
                    true,
                    {},
                    token
                )

                if (res.error) setError(res.error.message)
                else {
                    const json = await res.json()
                    setLoading(false)
                    if (res.ok) {

                        setCohort(json)
                        getCohort(cohortId)
                        setSuccess('Student added')
                    }
                    else setError(json.message)
                }
            }

        } catch (err) {
            console.error(err)
        }
    }

    const getCohort = async (cohortId) => {
        setLoading(true)
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const urlencoded = new URLSearchParams();

            const tokenObj = await getTokens()
            const token = tokenObj?.access?.token


            if (token) {



                const res = await fetchData(
                    `${config.serverURL}/api/cohorts/${cohortId}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )

                if (res.error) setError(res.error.message)
                else {
                    const json = await res.json()

                    setLoading(false)
                    if (res.ok) {

                        setCohort(json)
                        // setSuccess('Cohort update successful')
                    }
                    else setError(json.message)
                }
            }

        } catch (err) {
            console.error(err)
        }
    }

    return (
        <AdminContext.Provider
            value={{
                students,
                alumni,
                staff,
                cohorts,
                cohort,
                error,
                success,
                loading,
                getUsers,
                setCohort,
                getHubspotNewStudents,
                getCohorts,
                getCohort,
                handleUpdateCohort,
                sendWelcomeEmail,
                cohortsDropDown,
                addStudent,
                uploadFile,
                deleteFile
            }}
        >
            {children}
        </AdminContext.Provider>
    )
}


