import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const humanReadableHeader = (property) => property.split("_").map(a => a.charAt(0).toUpperCase() + a.substr(1)).join(" ");


const CustomTableHead = (props) => {
    const { classes,
        dataTypes,
        // onSelectAllClick,
        order, orderBy,
        // numSelected,
        // rowCount,
        onRequestSort, columns } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.cell}
                    padding="checkbox">
                    {/* <Checkbox
                        padding="none"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    /> */}
                </TableCell>
                {columns.map((type) => (
                    <TableCell
                        className={classes.tableHead}
                        key={type}
                        align={dataTypes[type]?.numeric ? 'right' : 'left'}
                        padding="none"
                        sortDirection={orderBy === dataTypes[type]?.id ? order : false}
                    >
                        {  dataTypes[type]?.sortable ? <TableSortLabel
                            active={orderBy === type}
                            direction={orderBy === type ? order : 'asc'}
                            onClick={createSortHandler(type)}
                        >
                            {humanReadableHeader(type)}
                            {orderBy === type ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel> : humanReadableHeader(type)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default CustomTableHead

CustomTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    dataTypes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};