import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from "context/authContext"
import { Faqs } from 'components';
import { FaqProvider } from "context/faqContext"
import { StyledHeading } from '../components/styles/Docs';
import { Unauthorized } from 'components/auth/Unauthorized'
import { FaqContext } from "context/faqContext"
import { Edit as EditIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown'
import MDEditor from '@uiw/react-md-editor';
import Search from 'components/faqs/searchFaqs'
import Faq from 'components/faqs/faqItem'
import FaqListItem from 'components/faqs/faqListItem'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Zoom from '@material-ui/core/Zoom';
import { Avatar, Grid, TextFieldLabel, SwitchLabel, DropDownLabel } from 'components/common';
import { courseString, courseStringDropDown, courseLengthDropDown } from "utils/definitions"
import { Cohort } from 'components';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import styled, { css, keyframes } from 'styled-components';
import { useParams } from "react-router-dom";
import { TypeIcon } from 'components/common'

const Container = styled('div')`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
export const FaqSearchPage = () => {
    const { user: loggedUser } = useContext(AuthContext)

    const isAdmin = loggedUser.role === 'admin' || false
    const { faq, getFaq, setFaq } = useContext(FaqContext)


    useEffect(() => {
        setFaq()
    }, [])


    const [edit, setEdit] = useState(false)

    const handleAdd = () => {
        setEdit(true)
        setFaq({ title: 'Describe the issue', md: 'Problem Solution', tags: [] })
    }

    const handleClear = () => {
        setEdit(false)
        setFaq()
    }

    const changeEdit = () => { setEdit(!edit) }

    return (
        <Container>
            {faq ? <Grid item xs={12}>
                <Faq edit={edit} setEdit={setEdit} faq={faq} handleClear={handleClear} />


                <SwitchLabel
                    checked={edit}
                    onChange={changeEdit}
                    label="Edit"
                    icon={<EditIcon color={edit ? "primary" : "disabled"} />}
                />
            </Grid> :
                <>
                    <Grid item xs={10}>
                        <Search />
                        {/* <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={TypeIcon('web')}
                >
                    Save
      </Button> */}
                    </Grid>

                    <Grid item xs={1}>
                        <Fab onClick={handleAdd} color="primary" aria-label="add">
                            <AddIcon />
                        </Fab></Grid>
                </>}
        </Container>)
}



