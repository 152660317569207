import React from 'react'
import moment from 'moment'
import { TextField, Label } from 'components/common';

export const DatePickerLabel = ({ format, name = '', label = '', value = '', edit, handleUpdate }) => {
  let date
  switch (format) {
    case 0: date = new Date(value).toLocaleDateString(
      'fr-CA',
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }
    ).replace(/\//g, "-")
      break;
    case 1: date = moment(value).fromNow()
      break;
    default: date = new Date(value).toLocaleDateString(
      'de-DE',
      {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
      }
    )
      break;
  }

  return edit ? <TextField
    data-testid="username-input"
    label={label}
    name={name}
    type="date"
    defaultValue={date}
    onChange={handleUpdate}
    autoComplete="off"
    margin="normal"
  /> :
    <>
      {label && <Label variant="subtitle2" gutterBottom>
        {label}
      </Label>}
      <Label variant={"subtitle1"} >
        {date}
      </Label>
    </>
}
