import useFetch from 'utils/useFetch'
import config from 'config';


const useTokens = () => {
  const { fetchData } = useFetch()

  const setLocalStorage = (data, key) => (window.localStorage[key] = JSON.stringify(data))

  const clearLocalStorage = (key) => localStorage.removeItem(key)

  const getTokens = async () => {

    const tokenObject = window.localStorage.caBerlinTokens ? JSON.parse(window.localStorage.caBerlinTokens) : null
    if (tokenObject) {
      if (new Date(tokenObject.access?.expires) > new Date()) {
        return tokenObject
      }
      else if (new Date() < new Date(tokenObject.refresh?.expires)) {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();

        urlencoded.append("refreshToken", tokenObject.refresh.token);

        var requestOptions = {
          headers: myHeaders,
          redirect: 'follow'
        };

        const res = await fetchData(
          `${config.serverURL}/api/auth/refresh-tokens`,
          "POST",
          urlencoded,
          false,
          requestOptions
        )

        if (res.error) {
          console.log('res.err :>> ', res.err);
          // setError(res.error)
        }
        else {
          if (res.ok) {
            const tokens = await res.json()
            setLocalStorage(tokens, 'caBerlinTokens')
            return tokens
          }

        }

      }
      else clearLocalStorage('caBerlinTokens')
    }
  }
  return { getTokens }
}

export default useTokens
