import React from 'react';
import { Alert as MaterialAlert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: theme.spacing(10),
        // width: '500px',
        left: '50%',
        transform: ' translateX(-50%)',
        zIndex: 999999999,

    },
    alert: {
        display: 'flex',
        width: '100%',
    }
}));



export const Alert = ({ severity, title, text = '', strong = '' }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MaterialAlert className={classes.alert} severity={severity}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {text}
                {/* -<strong>{strong}</strong> */}
            </MaterialAlert>
        </div>)
}