import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CustomTableCell from './CustomTableCell';
import CustomTableHead from './CustomTableHead';
// import CustomTableToolbar from './CustomTableToolbar';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import { Link } from 'utils/link'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        // marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    selectTableCell: {
        display: "flex",
        border: 'none',
        // flexDirection: "column",
        // width: 60
    },
    tableCell: {
        width: 130,
        paddingLeft: 0,
        border: 'none',
        height: 20
    },
    tableHead: {
        border: 'none',
    },
    input: {
        width: 130,
        height: 20
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        // minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export const DataTable = ({ data, admin, columns, handleUpdate, dataTypes, tableType }) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [rows, setRows] = React.useState(data);
    const [previous, setPrevious] = React.useState({});

    const onToggleEditMode = id => {
        selectedRows(id)
        setRows(state => {
            return rows.map(row => {
                if (row.id === id) {
                    return { ...row, isEditMode: !row.isEditMode };
                }
                return row;
            });
        });
    };

    const onChange = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({ ...state, [row.id]: row }));
        }
        const name = e.target.name;

        const value = dataTypes[name]?.type == 'boolean' ? e.target.checked : e.target.value;

        const { id } = row;
        const newRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setRows(newRows);
    };
    const onValid = (e, row) => {
        handleUpdate(row.id, row)
        onToggleEditMode(row.id);
        selectedRows(row.id)
        setPrevious(state => {
            delete state[row.id];
            return state;
        });
    };

    const onRevert = id => {
        const newRows = rows.map(row => {
            if (row.id === id) {
                return previous[id] ? { ...previous[id], isEditMode: !row.isEditMode } : { ...row, isEditMode: !row.isEditMode };;
            }
            return row;
        });
        setPrevious(state => {
            delete state[id];
            return state;
        });
        setRows(newRows);
        selectedRows(id)
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const selectedRows = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/* {admin && <CustomTableToolbar numSelected={selected.length} />} */}
                <TableContainer>
                    <Table

                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <CustomTableHead
                            classes={classes}
                            dataTypes={dataTypes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            columns={columns}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) => for pagination */
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}

                                        >


                                            {
                                                row.isEditMode ? (
                                                    <TableCell padding="default" className={classes.selectTableCell}>
                                                        { admin && <>
                                                            <IconButton
                                                                aria-label="done"
                                                                onClick={() => onValid(row.id, row)}
                                                            >
                                                                <DoneIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="revert"
                                                                onClick={() => onRevert(row.id)}
                                                            >
                                                                <RevertIcon />
                                                            </IconButton>
                                                        </>}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell padding="none" className={classes.selectTableCell}>
                                                        <Link to={`/${tableType}/${row.id}`}>

                                                            <IconButton
                                                                aria-label="info"

                                                            >
                                                                <InfoRoundedIcon />
                                                            </IconButton>
                                                        </Link>
                                                        {admin && <IconButton
                                                            aria-label="edit"
                                                            onClick={() => onToggleEditMode(row.id)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>}


                                                        {/* <Checkbox
                                                        onClick={() => selectedRows(row.id)}
                                                        checked={isItemSelected}
                                                    /> */}
                                                    </TableCell>
                                                )
                                            }

                                            {columns.map(column =>
                                                <TableCell key={`${row.id}-${column}`}
                                                    padding={row.isEditMode ? "default" : "none"}
                                                    align="left"
                                                    className={classes.tableCell}>
                                                    <CustomTableCell
                                                        // classes={classes}
                                                        dataTypes={dataTypes}
                                                        tableType={tableType}
                                                        {...{ row, name: column, onChange }} />
                                                </TableCell>)
                                            }


                                        </TableRow>

                                    );
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {admin &&
                    <Fab size="small" color="primary" aria-label="add">
                        <AddIcon htmlColor="#FFF" />
                    </Fab>} */}
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
            </Paper>
        </div>
    );
}

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    dataTypes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    tableType: PropTypes.string.isRequired,
    handleUpdate: PropTypes.func,
}