import React, { useState, useContext } from "react"
import { AuthContext } from "context/authContext"
import Typography from '@material-ui/core/Typography';
import { SignForm, TextField, Spinner, Label, Grid } from 'components/common'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },


    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));
export const ForgotPassword = () => {
    const { handleForgotPassword, loading } = useContext(AuthContext)
    const classes = useStyles();

    const [state, setState] = useState({
        emailOrUsername: '',
    })

    const handleUpdate = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        return handleForgotPassword(state)
    }


    return (

        <form className={classes.form} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        data-testid="username-input"
                        label="Email or Username"
                        name="emailOrUsername"
                        value={state.emailOrUsername}
                        onChange={handleUpdate}
                        margin="normal"
                        placeholder="Enter your username or email"
                    />
                </Grid>
                <Grid center="true" item xs={12}>
                    <Button
                        data-testid="sign-in-button"
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                </Button>
                </Grid>
                <Grid item xs={12}>
                    <Label style={{ textAlign: 'center' }}>You will receive an email with a recovery link.</Label>
                </Grid>
            </Grid>
        </form>
    );
};

