import React, { useState, useContext, useEffect } from 'react'
import { Avatar, Grid, TextFieldLabel, TextField, SwitchLabel, DropDownLabel } from 'components/common';
import { courseString, courseStringDropDown, courseLengthDropDown } from "utils/definitions"
import { Cohort } from 'components';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AuthContext } from "context/authContext"
import { FaqContext } from "context/faqContext"
import { Edit as EditIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown'
import mdxComponents from 'components/mdxComponents';
import MDEditor from '@uiw/react-md-editor';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import MDX from '@mdx-js/runtime'
import { unescape } from 'html-escaper';
import { Markdown } from 'components/markdown'


const Faq = ({ faq, edit, handleClear }) => {

  const { handleUpdateFaq } = useContext(FaqContext)

  const [newHashtags, setNewHashtags] = useState('')

  const handleAddNewHashtags = () => {
    if (newHashtags) {
      setState({
        ...state,
        tags: [...state.tags, ...newHashtags.split(",")]
      })
      setNewHashtags('')
    }
  }

  useEffect(() => {
    setState(faq)
  }, [faq])

  const [state, setState] = useState(faq)

  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleUpdateMd = md => {
    setState({
      ...state,
      md,
    })
  }

  const handleSubmit = () => {
    handleUpdateFaq(faq.id, state)
  }



  const handleDelete = (chipToDelete) => () => {
    setState({
      ...state,
      tags: state.tags.filter(tag => tag !== chipToDelete)
    });
  }
  return (
    <>
      <Grid container row spacing={2} center="true">
        <Grid item xs={12} >
          <TextFieldLabel label="Title" name="title" value={state.title} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={12}>
          {edit ?
            <Markdown md={unescape(state.md)} handleUpdateMd={handleUpdateMd} />
            /* <MDEditor
              value={state.md}
              name="md"
              onChange={handleUpdateMd}
            /> */
            :
            <MDX components={mdxComponents}>{unescape(state.md)}</MDX>}
        </Grid>
        <Grid center item xs={12} >
          {state?.tags && state.tags.map((tag, index) =>
            <Chip
              key={index}
              label={tag}
              clickable
              color="primary"
              onDelete={edit && handleDelete(tag)}
              deleteIcon={edit && <ClearIcon />}
            />)}
        </Grid>
        {edit &&
          <Grid item xs={12}
          // className={classes.inHash}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="standard-adornment-password">Tags</InputLabel>
              <Input
                type={'text'}
                fullWidth
                onChange={(e) => setNewHashtags(e.target.value)}
                value={newHashtags}
                placeholder='Add Hashtags separated by ","'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleAddNewHashtags}
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>}
        <Grid center item xs={12} >
          {edit && <Button
            data-testid="sign-up-button"
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleClear}
          >Cancel
          </Button>}
          {edit && <Button
            data-testid="sign-up-button"
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >{faq.id ? "Update" : "Create"}
          </Button>}

        </Grid>
      </Grid>
    </>
  )
}

export default Faq