import React from 'react';
import { Sidebar, ListItem } from 'components/styles/Sidebar';

const RightSidebar = ({ headers }) => (

    <Sidebar >
        <ul className={'rightSideBarUL'}>
            <li className={'rightSideTitle'}>CONTENTS</li>
            {headers.map((header, index) =>
                <ListItem key={index} to={`#${header.to}`} level={1}>
                    {header.text}
                </ListItem>)
            }
        </ul>
    </Sidebar>
);



export default RightSidebar;
