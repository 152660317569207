
const useFetch = () => {
  const fetchData = async (url, method, body, authenticated, options, token) => {

    try {
      if (authenticated) {

        if (!options.headers) {
          options.headers = {}
        }
        options.headers["Authorization"] = `Bearer ${token}`
      }
      options.method = method
      if (method !== "GET") {
        options.body = body
      }
      const res = await fetch(url, options)

      return res

    } catch (error) {
      return { error }
    }
  }

  return { fetchData }
}

export default useFetch
