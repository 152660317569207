import React, { useState, useContext, useEffect } from 'react';
import { AdminContext } from "context/adminContext"
import { Avatar, Grid, TextFieldLabel, SwitchLabel } from 'components/common';
import { AuthContext } from "context/authContext"
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Edit as EditIcon } from '@material-ui/icons';
import { DataTable } from 'components';
import { dataTypes } from 'utils/definitions'
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import { AddStudent } from './AddStudent'

import { Link } from 'utils';

export const Cohort = ({ cohort }) => {
  // const { name, start_date, id: cohortId, logo = '', calendars, img } = cohort

  const { user: loggedUser, handleUpdateUser } = useContext(AuthContext)

  const { handleUpdateCohort, success, uploadFile, deleteFile } = useContext(AdminContext)

  const [state, setState] = useState({
    ...cohort,
    calendarWeb: cohort?.calendars?.web || '',
    calendarData: cohort?.calendars?.data || ''
  })

  const isAdmin = loggedUser.role === 'admin' ? true : false

  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (success) setEdit(false)
  }, [success])

  useEffect(() => {
    console.log(`cohort`, cohort)
    setState({
      ...cohort,
      calendarWeb: cohort?.calendars?.web || '',
      calendarData: cohort?.calendars?.data || ''
    })
  }, [cohort])

  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = () => {
    handleUpdateCohort(state.id, state)
  }

  const handleDeleteImg = (e) => {
    deleteFile('cohorts', state.id)
    setState({
      ...state,
      img: '',
    })
  }
  const handleUploadImg = async (e) => {

    const file = e.target.files[0]
    const link = await uploadFile(file, 'cohorts', state.id)
    setState({
      ...state,
      img: link,
    })

  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const usersColumns = ["course", "username", "firstname"]

  return (
    <>
      <Grid container spacing={2} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          {isAdmin && <SwitchLabel
            persistant
            checked={edit}
            onChange={handleEdit}
            label={"Edit"}
            icon={<EditIcon color={edit ? "primary" : "disabled"} />}
          />}
        </Grid>
        <Grid item xs={edit ? 12 : 6} >
          <Grid container justify="center" >
            {/* <Link to={state.img}> */}
            <Avatar type="cohorts" name="logo" edit={edit} id={cohort.id} handleUploadImg={handleUploadImg} handleDeleteImg={handleDeleteImg} srcImg={state.img} src={state.logo} alt={state.name} size="large" />
            {/* </Link> */}
            {edit && <TextFieldLabel label="Logo URL" name="logo" value={state.logo} edit={edit} handleUpdate={handleUpdate} />
            }
          </Grid>
        </Grid>


        <Grid item xs={edit ? 12 : 6} >
          <TextFieldLabel label="Name" name="name" value={state.name} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={edit ? 12 : 6} >
          <TextFieldLabel label="AKA" name="aka" value={state.aka} edit={edit} handleUpdate={handleUpdate} />
        </Grid>

        <Grid item xs={edit ? 12 : 6}>
          <TextFieldLabel label="Start Date" name="start_date" edit={edit && isAdmin} type="date" handleUpdate={handleUpdate} value={edit ? state.start_date : new Date(state.start_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          )} />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextFieldLabel label="End Date" name="end_date" edit={edit && isAdmin} type="date" handleUpdate={handleUpdate} value={edit ? state.end_date : new Date(state.end_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          )} />
        </Grid> */}
        {isAdmin && <Grid item xs={edit ? 12 : 6} >
          <TextFieldLabel label="Calendar Web" name="calendarWeb" value={state.calendarWeb} edit={edit} handleUpdate={handleUpdate} />
        </Grid>}
        {isAdmin && <Grid item xs={edit ? 12 : 6} >
          <TextFieldLabel label="Calendar Data" name="calendarData" value={state.calendarData} edit={edit} handleUpdate={handleUpdate} />
        </Grid>}

        {/* {isAdmin && <Grid center="true" item xs={12} >
          <Button
            data-testid="sign-in-button"
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => sendWelcomeEmail(cohort.id)}
          >
            Send Welcome Email
        </Button>
        </Grid>} */}
      </Grid>
      <Typography variant="h6" >Members</Typography>
      {cohort.users && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="users" handleUpdate={handleUpdateUser} data={cohort.users} columns={usersColumns} />}

      {isAdmin && edit && <IconButton variant="outlined" color="primary" onClick={handleOpen}>
        <AddIcon />
      </IconButton>}
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <AddStudent cohortId={state.id} />
      </Dialog>
      <Grid center="true" item xs={12}  >
        {edit && <Button
          data-testid="sign-up-button"
          type="button"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >Update
        </Button>}
      </Grid>

    </>
  )
}
