import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export const PhotoConsent = ({ handleAction }) => {


    return (<>

        <DialogTitle id="alert-dialog-title">{"Photo Waiver Release"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                I hereby authorize that Code Academy Berlin Gbr has my permission to use my photograph publically to promote the academy. I understand that the images may be used in print publications, online publications, presentations, websites, and social media. I also understand that no royalty, fee or other compensation shall become payable to me by reason of such use.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleAction(false)} color="primary">
                Disagree
          </Button>
            <Button onClick={() => handleAction(true)} color="primary" autoFocus>
                Agree
          </Button>
        </DialogActions>
    </>
    );
}