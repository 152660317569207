import React, { useContext, useEffect, useState } from "react"
import { AdminContext } from "../context/adminContext"
import { AuthContext } from "../context/authContext"
import { DataTable } from 'components';
import { StyledHeading } from '../components/styles/Docs';
import { dataTypes } from 'utils/definitions'
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';






function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const AdminPage = () => {

    const { getUsers, users, getCohorts, cohorts } = useContext(AdminContext)
    const { handleUpdateUser, user } = useContext(AuthContext)
    const { handleUpdateCohort, success, sendWelcomeEmail } = useContext(AdminContext)

    const isAdmin = user.role === 'admin' || false
    const [value, setValue] = useState(0);



    const handleChange = (event, newValue) => {
        if (newValue == 1 && !users) getUsers()
        if (newValue == 0 && !cohorts) getCohorts()
        setValue(newValue);
    };

    useEffect(() => {
        getCohorts()
    }, [])

    const usersColumns = ["firstname", "lastname", "email", "start_date"]
    const cohortsColumns = ["logo", "name", "aka", "start_date"]

    return (
        <>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab label="Cohorts" {...a11yProps(0)} />
                    <Tab label="Users" {...a11yProps(1)} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {cohorts && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="cohorts" handleUpdate={handleUpdateCohort} data={cohorts} columns={cohortsColumns} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {users && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="users" handleUpdate={handleUpdateUser} data={users} columns={usersColumns} />}
            </TabPanel>

        </>
    )
}