import React, { useState, useContext, useEffect } from 'react'
import { Avatar, Grid, TextFieldLabel, SwitchLabel, DropDownLabel, DatePickerLabel } from 'components/common';
import { courseString, courseStringDropDown, courseLengthDropDown } from "utils/definitions"
import { Cohort } from 'components';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { AuthContext } from "context/authContext"
import { AdminContext } from "context/adminContext"
import { Edit as EditIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Link } from 'utils/link';
import { GitHubRepos } from './GitHubRepos';
import { PhotoConsent } from './PhotoConsent';
import Dialog from '@material-ui/core/Dialog';
import PhotoCameraIcon from '@material-ui/icons/PhotoCameraOutlined';
import InfoIcon from '@material-ui/icons/Info';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
export const Profile = ({ user, hideCohort }) => {
  const { handleUpdateUser, user: loggedUser, success } = useContext(AuthContext)
  const { cohortsDropDown, getUsers, deleteFile, uploadFile } = useContext(AdminContext)

  const [state, setState] = useState(user)

  const isAdmin = loggedUser.role === 'admin' || false

  const isUser = user.id === loggedUser.id || false

  const [edit, setEdit] = useState(false)
  const [openRepos, setOpenRepos] = useState(false);
  const [photoConsent, setPhotoConsent] = useState(false);
  const changeEdit = () => { setEdit(!edit) }

  useEffect(() => {
    if (success) setEdit(false)
  }, [success])
  useEffect(() => {
    setState(user)
  }, [user])

  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }
  const handleUpdateBoolean = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
  }
  const handleConsentDialog = (consent) => {
    setState({
      ...state,
      photoConsent: consent,
    })
    setPhotoConsent(false);
  }

  const handleSubmit = () => {
    handleUpdateUser(user.id, state)
    getUsers()
  }
  const handleDeleteImg = () => {
    deleteFile('users', user.id)
    setState({
      ...state,
      img: '',
    })
  }
  const handleUploadImg = async (e) => {

    const file = e.target.files[0]
    const link = await uploadFile(file, 'users', user.id)
    setState({
      ...state,
      img: link,
    })

  }

  const handleOpenRepos = () => {
    setOpenRepos(true);
  };

  const handleCloseRepos = () => {
    setOpenRepos(false);
  };
  const handleOpenPhotoConsent = () => {
    setPhotoConsent(true);
  };

  const handleClosePhotoConsent = () => {
    setPhotoConsent(false);
  };

  //TODO auto generate profile from fields
  // const RenderField = ({ row, name, dataTypes, onChange, }) => {
  //   const { isEditMode } = row;

  //   switch (dataTypes[name]?.type) {
  //     case 'string':
  //       return <TextFieldLabel name={name} value={row[name]} edit={isEditMode}
  //         handleUpdate={e => onChange(e, row, name)}
  //       />;
  //     case 'date':
  //       return <DatePickerLabel format={0} name={name} value={row[name]} edit={isEditMode}
  //         handleUpdate={e => onChange(e, row)} />;
  //     case 'dropdown':
  //       return <DropDownLabel name={name} edit={isEditMode} handleUpdate={e => onChange(e, row, name)} options={dataTypes[name].options} value={row[name]} />
  //     case 'avatar':
  //       return <Avatar type={tableType} name={name} edit={isEditMode} id={row.id} srcImg={row.img} handleUpdate={e => onChange(e, row)} src={row.avatar} alt={row.firstname} size="small" />


  //     default:
  //       return <TextFieldLabel name={name} value={row[name]} edit={isEditMode}
  //         handleUpdate={e => onChange(e, row)}
  //       />;
  //   }
  // };

  console.log(`state`, state)


  return (
    <>
      <Grid container justify="center" style={{ textAlign: 'center' }} spacing={2}>


        {(isUser || isAdmin) && <Grid item xs={12}>
          <SwitchLabel
            persistant
            checked={edit}
            onChange={changeEdit}
            label="Edit"
            icon={<EditIcon color={edit ? "primary" : "disabled"} />}
          />
        </Grid>}
        <Grid item xs={edit ? 12 : 6} >
          <Grid container justify="center" >

            <Avatar type="users" name="avatar" edit={edit} id={state.id} handleUploadImg={handleUploadImg} handleDeleteImg={handleDeleteImg} srcImg={state.img} src={state.avatar} alt={state.firstname} size="large" />

            {edit && <TextFieldLabel label="Avatar URL" name="avatar" value={state.avatar} edit={edit} handleUpdate={handleUpdate} />}
          </Grid>
        </Grid>
        <Grid item xs={edit ? 12 : 6} >
          <TextFieldLabel label="Username" name="username" value={state.username} edit={edit} handleUpdate={handleUpdate} />
          <TextFieldLabel label="About" name="about" value={state.about} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={edit ? 12 : 6}>
          <TextFieldLabel label="First Name" name="firstname" value={state.firstname} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={edit ? 12 : 6}>
          <TextFieldLabel label="Last Name" name="lastname" value={state.lastname} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        {(isUser || isAdmin) && <Grid item xs={12}>
          <TextFieldLabel label="Email" name="email" value={state.email} edit={edit && isAdmin} handleUpdate={handleUpdate} />
        </Grid>}
        <Grid item xs={edit ? 12 : 6} >
          <Grid container justify="center" >
            <Grid item xs={edit ? 12 : 5}>
              <TextFieldLabel label="Github" name="gitHub" value={edit ? state.gitHub : <Link color="primary" to={`https://github.com/${state.gitHub}`}>{state.gitHub}</Link>} edit={edit} handleUpdate={handleUpdate} />
            </Grid>
            {state.gitHub && !edit && <Grid item xs={1}>


              <IconButton variant="outlined" color="primary" onClick={handleOpenRepos}>
                <FolderOpenIcon />
              </IconButton>

              <Dialog onClose={handleCloseRepos} aria-labelledby="simple-dialog-title" open={openRepos}>
                <GitHubRepos username={state.gitHub} />
              </Dialog>

            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={edit ? 12 : 5}>
          <TextFieldLabel label="LinkedIn" name="linkedIn" value={edit ? state.linkedIn : <Link color="primary" to={`https://www.linkedin.com/in/${state.linkedIn}`}>{state.linkedIn}</Link>} edit={edit} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={6}>
          <DropDownLabel label="Course" name="course" edit={edit && isAdmin} handleUpdate={handleUpdate} options={courseStringDropDown} value={edit ? state.course : courseString[state.course]} />
        </Grid>
        <Grid item xs={6}>
          <DropDownLabel label="Course Length" name="course_length" edit={edit && isAdmin} handleUpdate={handleUpdate} options={courseLengthDropDown} value={state.course_length} />
        </Grid>
        <Grid item xs={6}>
          <DatePickerLabel format={2} label="Start Date" name="start_date" value={state.start_date} edit={edit && isAdmin}
            handleUpdate={handleUpdate} />
          {/* <DropDownLabel label="Start Date" name="start_date" edit={edit && isAdmin} handleUpdate={handleUpdate} options={cohortsDropDown} value={edit ? state.start_date : new Date(state.start_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          )} /> */}
        </Grid>
        <Grid item xs={6}>
          <DatePickerLabel format={2} label="End Date" name="end_date" value={state.end_date} edit={edit && isAdmin}
            handleUpdate={handleUpdate} />
          {/* <DropDownLabel label="End Date" name="end_date" edit={edit && isAdmin} handleUpdate={handleUpdate} options={cohortsDropDown} value={edit ? state.start_date : new Date(state.start_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          ) || null} /> */}
        </Grid>

        {(isUser || isAdmin) && <Grid item xs={6}  >
          <Grid item xs={6} column="true" >
            <Typography style={{ textAlign: 'center' }} variant="body1" gutterBottom>Photo Consent</Typography>
          </Grid>
          <Grid item xs={6} column="true"  >
            <SwitchLabel
              name="photoConsent"
              checked={state?.photoConsent || false}
              onChange={handleUpdateBoolean}
              edit={edit}
              icon={<PhotoCameraIcon color={state?.photoConsent ? "primary" : "disabled"} />}
            />
            {edit && <>
              <IconButton variant="outlined" color="primary" onClick={handleOpenPhotoConsent}>
                <InfoIcon />
              </IconButton>

              <Dialog onClose={handleClosePhotoConsent} aria-labelledby="simple-dialog-title" open={photoConsent}>
                <PhotoConsent handleAction={handleConsentDialog} />
              </Dialog>
            </>}
          </Grid>
        </Grid>}
        {(isUser || isAdmin) && <Grid item xs={6}  >
          <Grid item xs={6} column="true" >
            <Typography style={{ textAlign: 'center' }} variant="body1" gutterBottom>Share profile with recruiters</Typography>
          </Grid>
          <Grid item xs={6} column="true" >
            <SwitchLabel
              name="shareProfile"
              checked={state?.shareProfile || false}
              onChange={handleUpdateBoolean}
              edit={edit}
              icon={<WorkOutlineIcon color={state?.shareProfile ? "primary" : "disabled"} />}
            />
          </Grid>
        </Grid>}
        <Grid center="true" item xs={12} >
          {edit && <Button
            data-testid="sign-up-button"
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >Update
        </Button>}
        </Grid>

      </Grid>
      {/* {user.cohort && !edit && !hideCohort && <Grid item xs={12} >
        <Typography color="primary" style={{ textAlign: 'center' }} variant="h5" gutterBottom>Cohort</Typography>
       <Cohort cohort={user.cohort} /> 
      </Grid> */}

    </>
  )
}
