import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from "context/authContext"
import { AdminContext } from "context/adminContext"
import { Edit as EditIcon } from '@material-ui/icons';
import { Cohort } from 'components';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Zoom from '@material-ui/core/Zoom';
import { Avatar, Grid, TextFieldLabel, SwitchLabel, DropDownLabel } from 'components/common';
import { courseString, courseStringDropDown, courseLengthDropDown } from "utils/definitions";
import { StyledHeading } from 'components/styles/Docs';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import styled, { css, keyframes } from 'styled-components';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { TypeIcon } from 'components/common'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  @media only screen and (max-width: 767px) {
    display: block;
    padding-left: 2px;
      padding-right: 2px;
  }
`;


export const CohortPage = () => {
    const { user: loggedUser } = useContext(AuthContext)
    let history = useHistory();
    let location = useLocation();

    const isAdmin = loggedUser.role === 'admin' || false
    let { cohortId } = useParams();
    const { getCohort, cohort, setCohort } = useContext(AdminContext)


    useEffect(() => {
        setCohort()
        getCohort(cohortId)
    }, [])

    const handleBack = () => {
        setCohort()
        // console.log('location.state :>> ', location.state);
        // let { from } = location.state || { from: { pathname: "/family" } };

        history.goBack();

    }


    return (
        <Container>
            {cohort && <Grid item xs={12}>
                <Fab size="small" color="primary" onClick={handleBack} >
                    <ArrowBackIosRoundedIcon htmlColor={"#FFF"} />
                </Fab>


            </Grid>}
            {cohort && <Cohort cohort={cohort} />}
        </Container>)
}



