import React, { useContext, useEffect, useState } from "react"
import { AdminContext } from "../context/adminContext"
import { AuthContext } from "../context/authContext"
import { DataTable } from 'components';
import { StyledHeading } from '../components/styles/Docs';
import { dataTypes, usersColumns, cohortsColumns } from 'utils/definitions'
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const Wrapper = styled('div')`

  padding-top: 20px;
  width: 100%;
  /* max-width: 1500px; */
  padding-left: 20px;
padding-right: 20px;

  @media only screen and (max-width: 767px) {

      padding-left: 2px;
      padding-right: 2px;


  }
`;


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const FamilyPage = () => {

    const { getUsers, students, alumni, staff, getCohorts, cohorts } = useContext(AdminContext)
    const { handleUpdateUser, user } = useContext(AuthContext)
    const { handleUpdateCohort, success, sendWelcomeEmail } = useContext(AdminContext)

    const isAdmin = user.role === 'admin' || false
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleChange = (event, newValue) => {
        if (newValue == 0 && !cohorts) getCohorts()
        if (newValue == 1 && !students) getUsers('Client')
        if (newValue == 2 && !alumni) getUsers('Alumni')
        if (newValue == 3 && !staff) getUsers('Staff')

        setValue(newValue);
    };

    useEffect(() => {
        getCohorts()

    }, [])




    return (
        <Wrapper>
            <StyledHeading >Family</StyledHeading>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab label="Cohorts" {...a11yProps(0)} />
                    <Tab label="Students" {...a11yProps(1)} />
                    <Tab label="Alumni" {...a11yProps(2)} />
                    <Tab label="Staff" {...a11yProps(3)} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {cohorts && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="cohorts" handleUpdate={handleUpdateCohort} data={cohorts} columns={cohortsColumns(smUp)[user.role]} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {students && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="users" handleUpdate={handleUpdateUser} data={students} columns={usersColumns(smUp)[user.role]} />}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {alumni && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="users" handleUpdate={handleUpdateUser} data={alumni} columns={usersColumns(smUp)[user.role]} />}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {staff && <DataTable admin={isAdmin} dataTypes={dataTypes} tableType="users" handleUpdate={handleUpdateUser} data={staff} columns={usersColumns(smUp)[user.role]} />}
            </TabPanel>

        </Wrapper>
    )
}