import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'context/themeContext';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';

const ToggleTheme = () => {
  const { activeTheme, toggleTheme } = useContext(ThemeContext);

  const isDarkTheme = activeTheme === 'dark' ? true : false;

  const [checked, setChecked] = useState(isDarkTheme);

  useEffect(() => {
    const isDarkTheme = activeTheme === 'dark' ? true : false;

    const timer = setTimeout(() => {
      setChecked(isDarkTheme);
    }, 500);

    return () => clearTimeout(timer);
  }, [activeTheme]);



  return isDarkTheme ?
    <Slide direction="down" in={checked} >
      <IconButton color="inherit" onClick={toggleTheme} size="medium">
        <Brightness7Icon htmlColor="#FFF" />
      </IconButton>
    </Slide> :
    <Slide direction="down" in={!checked} >
      <IconButton color="inherit" onClick={toggleTheme} size="medium">
        <Brightness4Icon htmlColor="#FFF" />
      </IconButton>
    </Slide>;
};


export default ToggleTheme;
