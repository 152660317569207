import React from 'react';
import styled from 'styled-components';

export const Spinner = ({ size, loading, fill }) => (
  <StyledSpinner size={size} loading={loading} viewBox="0 0 525.36 476.04">
    {/* <svg id="svg2" height={size} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.36 476.04"> */}
    <title>PenroseLogo</title>
    <g id="layer1">
      <path id="path1" d="M220.9.71,406.45,331l-206.26.11-39.56,70.71,365.21.06L303,.5Z" transform="translate(-0.5 -0.5)" fill={fill ? fill : "#00bfaf"} />
      <path id="path2" d="M220.89.68.5,401.79l40.41,72.73L223.74,143.19l102,187.88h80.81Z" transform="translate(-0.5 -0.5)" fill={fill ? fill : "#00897d"} />
      <path id="path3" d="M223.79,143.25l40.29,74.22L160.45,401.85l365.41.19-38.47,74.5L40.92,474.49Z" transform="translate(-0.5 -0.5)" fill={fill ? fill : "#1b5954"} />
    </g>
    {/* </svg> */}
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
 /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  width: ${props => props.size};
  height: ${props => props.size};
  animation : ${props => props.loading && `blink 2s linear infinite`};

  @keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}
`;
