import React, { useState, useContext } from 'react'
import { AuthContext } from 'context/authContext'
import { Link } from 'utils/link'
import moment from 'moment'
import Picker from 'emoji-picker-react';
import { Grid, TextFieldLabel, Avatar, SwitchLabel, DatePickerLabel } from 'components/common';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'; import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import styled from 'styled-components';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import SendIcon from '@material-ui/icons/Send';
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";

const CommentsList = styled(List)`
width: 100%;
`;

const PostComment = styled.div`
display: flex;
background-color: ${props => props.theme.palette.background.default};
padding : ${props => `${props.theme.spacing(1)}px`}
`;
const Comment = ({ comment, newsId, crudComment }) => {
    const { user } = useContext(AuthContext)
    const [edit, setEdit] = useState(false)
    const [body, setBody] = useState(comment.body)
    const author = comment.user.id === user.id

    const handleDelete = () => {
        crudComment('DELETE', newsId, comment)
    }
    const handleEdit = () => {
        crudComment('PATCH', newsId, comment, body)
        setEdit(false)
    }
    const handleRevert = () => {
        setBody(comment.body)
        setEdit(false)
    }
    return (
        <>
            <ListItem>
                <ListItemAvatar>
                    <Link to={`/users/${comment.user.id}`} >
                        <Avatar type="users" name="avatar" edit={false} srcImg={comment.user.img} src={comment.user.avatar} alt={comment.user.firstname} size="xs" />
                    </Link>
                </ListItemAvatar>
                <ListItemText
                    primary={<TextFieldLabel
                        value={body}
                        handleUpdate={(e) => setBody(e.target.value)}
                        edit={author && edit} />}
                    secondary={comment.modified ?
                        <Link to={`/users/${comment.user.id}`} > {`${comment.user.firstname}, edited ${moment(comment.date).fromNow()}`} </Link>
                        : <Link to={`/users/${comment.user.id}`} > {`${comment.user.firstname}, ${moment(comment.date).fromNow()}`} </Link>}
                />
                <ListItemSecondaryAction>
                    {author && !edit && <Grid center="true" item xs={2} >
                        <IconButton edge="end" aria-label="edit" onClick={() => setEdit(true)} >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={handleDelete} edge="end" aria-label="delete" >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Grid>}
                    {edit && <Grid center="true" item xs={2} >
                        <IconButton onClick={handleRevert} edge="end" aria-label="delete" >
                            <RevertIcon fontSize="small" />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={handleEdit} >
                            <DoneIcon fontSize="small" />
                        </IconButton>
                    </Grid>}

                </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>)
}
export const Comments = ({ newsId, comments, crudComment }) => {
    const { user } = useContext(AuthContext)
    const [body, setBody] = useState('')
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onEmojiClick = (event, emojiObject) => {
        console.log('emojiObject :>> ', emojiObject);
        let msg = body
        setBody(msg += emojiObject.emoji);
    };
    const handlePostComment = (e) => {
        e.preventDefault()
        if (body !== '')
            crudComment('POST', newsId, null, body)
        setBody('')
    }
    return (
        <form>
            <CommentsList >
                {comments && comments.map(comment =>
                    <Comment newsId={newsId} key={comment._id} comment={comment} crudComment={crudComment} />)}
            </CommentsList>
            <PostComment>
                <Avatar type="users" name="avatar" edit={false} srcImg={user.img} src={user.avatar} alt={user.firstname} size="small" />

                <TextFieldLabel title placeholder="write a comment" value={body} edit={true}
                    handleUpdate={(e) => setBody(e.target.value)}
                />
                <IconButton aria-describedby={id} type="button" edge="end" aria-label="edit" onClick={handleClick} >
                    <InsertEmoticonIcon color="inherit" fontSize="default" />
                </IconButton>
                <Popover
                    open={open}
                    id={id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Picker onEmojiClick={onEmojiClick} />
                </Popover>
                <IconButton type="submit" edge="end" aria-label="edit" onClick={handlePostComment} >
                    <SendIcon color="primary" fontSize="large" />
                </IconButton>
            </PostComment>

        </form>
    )
}

