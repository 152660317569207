import React from 'react';
import styled from 'styled-components';

export const SVG = styled.svg`
  padding: 1rem;
  fill: ${props => props.fill ? props.fill : props.theme.palette?.type === 'light' ? props.theme.palette.primary.main : "#fff"};
  z-index: 999


`;
export const Logo = ({ width, height, fill = '', berlin }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" fill={fill} width={width} height={height} viewBox="0 0 500 500">
    <g>
      <g>
        {/* <defs>
          <rect id="SVGID_1_" width="500" height="500" />
        </defs> */}

        <path className="st0" d="M166.6,462.3L310.2,50.8c2.4-6.9-1.6-12.5-8.9-12.5H190.1c-3.6,0-6.7,0-6.7,0l-2.8,8.1L30.9,462.3" />
        <g className="st1">
          <g>
            <g>
              <path className="st2" d="M193.2,39.5l60.5,174.7l-8.6,5.4L183.4,41.5L193.2,39.5z" />
              <g>
                <g>
                  <polygon className="st3" points="251.8,220.5 191.4,45.7 200,40.3 261.6,218.5 							" />
                  <polygon className="st3" points="251.8,220.5 191.4,45.7 200,40.3 261.6,218.5 							" />
                </g>
              </g>
            </g>
            <g>
              <path className="st4" d="M193.2,39.5l60.5,174.7l-8.6,5.4L183.4,41.5L193.2,39.5z" />
              <g>

                <g>
                  <polygon className="st5" points="251.8,220.5 191.4,45.7 200,40.3 261.6,218.5 							" />
                </g>
              </g>
            </g>
          </g>
          <path className="st3" d="M335.3,462.3l-148.4-424c0,0,2.9,0.1,6.5,0.1h111.9c7.3,0,13.1,0,13.1,0l152.5,424" />
        </g>
      </g>
    </g>
  </SVG>
);
