
import React, { useState, useContext, useEffect } from "react"
import { SignForm, TextField, Section, Label, Grid, TextFieldLabel, SwitchLabel } from 'components/common'
import { AuthContext } from "../../context/authContext"
import { GoogleLogin } from 'react-google-login';
import { Avatar, Spinner } from 'components/common';
import Typography from '@material-ui/core/Typography';
import { Edit as EditIcon, Clear as ClearIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoConsent } from 'components/profile/PhotoConsent';
import Dialog from '@material-ui/core/Dialog';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },


  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));



export const SignUpForm = ({ email }) => {
  const { error, handleRegister, isUser, loading } = useContext(AuthContext)
  const classes = useStyles();

  const [state, setState] = useState({

    password: ``,
    username: ``,
    passwordRepeat: ``,
    email,
    photoConsent: false,
  })
  const [photoConsent, setPhotoConsent] = useState(false);

  useEffect(() => {
    if (state.email) checkEmail()
  }, [])

  const [edit, setEdit] = useState(false)
  const [registeredUser, setRegisteredUser] = useState(null)



  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleConsentDialog = (consent) => {
    console.log('consent :>> ', consent);
    setState({
      ...state,
      photoConsent: consent,
    })
    setPhotoConsent(false);
  }
  const handleOpenPhotoConsent = () => {
    setPhotoConsent(true);
  };
  const handleUpdateConsent = (e) => {
    setPhotoConsent(e.target.checked);
  };

  const handleClosePhotoConsent = () => {
    setPhotoConsent(false);
  };
  const handleSubmit = event => {
    event.preventDefault()
    // if (!googleProfile) return alert('Link a Google profile')
    if (!state.email) return alert('Registration email missing')
    else if (!state.password) return alert('Password missing')
    else if (!state.passwordRepeat) return alert('Password confirmation missing')
    else if (state.password !== state.passwordRepeat) return alert('Passwords do not match')
    else handleRegister(state)

  }


  const handleCheckEmail = (e) => {
    e.preventDefault()
    checkEmail()
  }

  const checkEmail = async () => {
    const user = await isUser(state.email)
    if (user) {
      setRegisteredUser(user)
      if (user?.firstname)
        setState({
          ...state,
          username: user.firstname,
        })
    }
    else setRegisteredUser(null)
  }


  return (
    <form className={classes.form} noValidate>
      <Grid container center >
        <Grid item xs={8}>
          <TextFieldLabel name="email" label="Registration Email" value={state.email} edit={registeredUser ? false : true} handleUpdate={handleUpdate} />
        </Grid>
        <Grid item xs={3}>

          {registeredUser ? <Button
            data-testid="sign-up-button"
            type="button"
            color="primary"
            variant="outline"
            onClick={() => setRegisteredUser(null)}
          >Edit
        </Button> :
            <Button
              data-testid="sign-up-button"
              type="button"
              color="primary"
              variant="outline"
              onClick={handleCheckEmail}
            >Confirm
        </Button>}
        </Grid>
      </Grid>

      {registeredUser &&
        <>
          <TextField
            variant="outlined"
            data-testid="username-input"
            label="Username"
            name="username"
            value={state.username}
            onChange={handleUpdate}
            autoComplete="off"
            margin="normal"
          />
          <TextField
            variant="outlined"
            data-testid="password-input"
            label="Password"
            type="password"
            name="password"
            required
            value={state.password}
            onChange={handleUpdate}
            autoComplete="off"
            margin="normal"
          />

          <TextField
            variant="outlined"
            data-testid="password-confirm-input"
            label="Confirm password"
            type="password"
            name="passwordRepeat"
            required
            value={state.passwordRepeat}
            onChange={handleUpdate}
            autoComplete="off"
            margin="normal"
          />
          <Grid item xs={6} column  >
            <Typography variant="body1" >Photo Consent</Typography>
            <SwitchLabel
              name="photoConsent"
              checked={state?.photoConsent || false}
              onChange={handleUpdateConsent}
              edit={true}
              icon={<PhotoCameraIcon color={state?.photoConsent ? "primary" : "disabled"} />}
            />

            <IconButton variant="outlined" color="primary" onClick={handleOpenPhotoConsent}>
              <InfoIcon />
            </IconButton>

            <Dialog onClose={handleClosePhotoConsent} aria-labelledby="simple-dialog-title" open={photoConsent}>
              <PhotoConsent handleAction={handleConsentDialog} />
            </Dialog>

          </Grid>

          <Typography color="error" data-testid="error-message">{error}</Typography>

          <Button
            data-testid="sign-up-button"
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.submit}

          >Sign up
        </Button>
        </>}
    </form>
  );
};

