import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import isAbsoluteUrl from 'is-absolute-url';
import styled from 'styled-components';

const StyledExternalLink = styled('a')`
 text-decoration: none;
  color: ${props => props.color === "primary" ? props.theme.palette.primary.main : props.theme.palette.text.default};
   &:hover {
    cursor: pointer;
  }
  `

const StyledLink = styled(props => <RouterLink {...props} />)`
  color: ${props => props.color === "primary" ? props.theme.palette.primary.main : props.theme.palette.text.default};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
   

`;

export const Link = ({ to, ...props }) =>
  isAbsoluteUrl(to) ? (
    <StyledExternalLink target="_blank" href={to} {...props}>
      {props.children}
    </StyledExternalLink>
  ) : (
    <StyledLink to={to}
      {...props}

    />
  );