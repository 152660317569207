import React, { useContext, useState } from "react";
import { Avatar } from 'components/common';
import { AuthContext } from "context/authContext"
import AccountCircle from '@material-ui/icons/AccountCircle';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import { fade, makeStyles } from '@material-ui/core/styles';
import ToggleTheme from './ToggleTheme';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { Link } from 'utils/link';
import ForumIcon from '@material-ui/icons/Forum';
import GitHubIcon from '@material-ui/icons/GitHub';
import LanguageIcon from '@material-ui/icons/Language';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Discord } from 'assets/SVG/Discord.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
export const NavMenu = (props) => {

    const { isAuthenticated, logout, user } = useContext(AuthContext)

    let history = useHistory();

    const handleClick = action => {
        if (!action) return;

        if (props.onClick) props.onClick(action);
    };

    const handleLogout = () => {
        logout()
        history.push('/login')
        handleMenuClose()
    }

    const handleLogin = () => {
        history.push('/login')
        handleMenuClose()
    }

    const handleProfile = () => {
        history.push('/users/me')
        handleMenuClose()
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const useStyles = makeStyles((theme) => ({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            color: '#FFF',
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        svg: {
            htmlColor: theme.props.MuiSvgIcon
        }
    }));


    const classes = useStyles();

    const menuId = 'primary-search-account-menu';

    const [anchorEl, setAnchorEl] = useState(null);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);


    const isMenuOpen = Boolean(anchorEl);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to="https://chat.google.com/u/1/room/AAAA8XXfNRE">
                <MenuItem >
                    <IconButton>
                        <ForumIcon />
                    </IconButton>
                    <p>Google Chat</p>
                </MenuItem>
            </Link>
            <Link to="https://codeacademyberlin.com">
                <MenuItem >
                    <IconButton>
                        <LanguageIcon />
                    </IconButton>
                    <p>Website</p>
                </MenuItem>
            </Link>
            <Link to="https://github.com/CodeAcademyBerlin">
                <MenuItem >
                    <IconButton>
                        <GitHubIcon />
                    </IconButton>
                    <p>GitHub</p>
                </MenuItem>
            </Link>
            <Link to="https://www.facebook.com/codeacademyberlin">
                <MenuItem >
                    <IconButton>
                        <FacebookIcon />
                    </IconButton>
                    <p>Facebook</p>
                </MenuItem>
            </Link>
            <Link to="https://www.instagram.com/codeacademyberlin/">
                <MenuItem >
                    <IconButton>
                        <InstagramIcon />

                    </IconButton>
                    <p>Instagram</p>
                </MenuItem>
            </Link>
            <Link to="https://docs.google.com/forms/d/1EVRw3P4ghx6JOANNG_laPPBT3wjNs9RkF8kKV8dwDxc">
                <MenuItem >
                    <IconButton>
                        <ExitToAppIcon />
                    </IconButton>
                    <p>Time Off Request</p>
                </MenuItem>
            </Link>
            <Link to="https://docs.google.com/forms/d/1Ua87HHZDnQJUxweAcnfdzzCEmbBiYDWb7GZNtSYwCIo">
                <MenuItem >
                    <IconButton>
                        <PriorityHighIcon />
                    </IconButton>

                    <p>Report Incident</p>
                </MenuItem>
            </Link>
            {user?.role === 'admin' && <Link to="https://forms.gle/75JK5z4N8nxGEmpTA">
                <MenuItem >
                    <IconButton>
                        <PriorityHighIcon />
                    </IconButton>

                    <p>Vacation & WFH request</p>
                </MenuItem>
            </Link>}
            {/* <Link to="https://discord.gg/tNapAKNXtf">
                <MenuItem >
                    <IconButton>
                        <SvgIcon className={classes.svg}>
                            <Discord />
                        </SvgIcon>

                    </IconButton>
                    <p>Discord</p>
                </MenuItem>
            </Link> */}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            {/* <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfile}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <ExitToAppIcon />
                </IconButton>
                <p>Sign out</p>
            </MenuItem>
        </Menu>
    );

    return isAuthenticated ?
        <div>
            <div className={classes.sectionMobile}>
                <Button color="inherit" onClick={handleMenuOpen}>Links</Button>
                <ToggleTheme />

                <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <MoreIcon htmlColor="#FFF" />
                </IconButton>
            </div>
            <div className={classes.sectionDesktop}>
                <Button color="inherit" onClick={handleMenuOpen}>Links</Button>
                <ToggleTheme />

                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <Avatar srcImg={user?.img} src={user?.avatar} alt={user?.firstname} />

                </IconButton>
            </div>
            {renderMobileMenu}
            {renderMenu}
        </div>
        : <div>
            <ToggleTheme />
            <Button onClick={handleLogin}>Login</Button>
        </div>
};
