import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #00897D;
  padding: 0.8rem 1rem;
  border-radius: 7px;
 
  transition: 0.3s;
width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  ${({ error }) =>
    error &&
    `
		border-color: #ff4136;
	`}

&&::focus {
    top: -20px;
  left: 0;
  color: #00897D;
  font-size: 12px;
}
  &::placeholder {
    color: #a7a7a7;
  }
`;
export const HalfInput = styled.input`
  width: 45%;
  box-sizing: border-box;
  border: 2px solid #00897D;
  padding: 0.8rem 1rem;
  border-radius: 7px;
  margin-bottom: 0.5rem;
  transition: 0.3s;

  ${({ error }) =>
    error &&
    `
		border-color: #ff4136;
	`}

  &::placeholder {
    color: #a7a7a7;
  }
`;
