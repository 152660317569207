import React, { createContext } from 'react';
import useDarkMode from 'utils/useDarkMode';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as SCThemeProvider } from "styled-components";
import CssBaseline from '@material-ui/core/CssBaseline';
import { teal, red } from '@material-ui/core/colors';
import { StylesProvider } from "@material-ui/styles";

const defaultContext = {
  activeTheme: 'light',
  toggleTheme: () => {
    throw new Error('toggleTheme() not implemented')
  }
}

export const ThemeContext = createContext(defaultContext);

export const ThemeProvider = ({ children }) => {

  const [activeTheme, toggleTheme] = useDarkMode();

  const defaultTheme = createMuiTheme({});

  const palette = {
    ...defaultTheme.palette,
    primary: { main: teal[600] },
    secondary: { main: red.A100 },
    background: {
      default: activeTheme === 'light' ? '#fff' : '#212121',
      paper: activeTheme === 'light' ? '#EDEBE9' : '#424242',
    },
    text: {
      default: activeTheme === 'light' ? '#3B454E' : '#fff',
    },
    type: activeTheme || 'light',
  }

  const muiTheme = createMuiTheme({
    ...defaultTheme,
    palette,
    mixins: {
      toolbar: {
        // minHeight: 55,
      },
    }
    ,
    props: {
      '&:hover': {
        MuiSvgIcon: {
          htmlColor: '#fff',

        }
      },
      MuiSvgIcon: {
        // htmlColor: '#fff',
        htmlColor: activeTheme === 'light' ? '#424242' : '#fff',

      }
    },
    typography: {
      h2: {
        paddingTop: '1.2rem',
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1.3rem',
        },
        [defaultTheme.breakpoints.up('md')]: {
          fontSize: '2rem',
        },
      },
      h3: {
        paddingTop: '1rem',
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        [defaultTheme.breakpoints.up('md')]: {
          fontSize: '	1.125rem',
        },
      },
      fontFamily: [
        // '-apple-system',
        'BlinkMacSystemFont',
        // '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <ThemeContext.Provider
      value={{
        activeTheme,
        toggleTheme
      }}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={muiTheme}>
            {children}
          </SCThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ThemeContext.Provider>
  )
}
