import React from 'react';
import { Link } from 'utils/link';
import List from '@material-ui/core/List';
import { ListItem } from 'components/common';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';



const TreeNode = ({ setCollapsed, collapsed, slug, navTitle, items }) => {
  const isCollapsed = collapsed[slug]



  const collapse = () => {
    setCollapsed(slug);
  }


  const active = window?.location?.pathname === `/content${slug}` ? true : false
  const hasChildren = items.length !== 0;

  return (
    <>
      { slug ?
        <Link to={`/content${slug}`}>
          <ListItem onClick={collapse} button isactive={active.toString()} >
            {navTitle && <ListItemText color="inherit" primary={navTitle} />}
            {hasChildren && <ListItemSecondaryAction>
              <IconButton onClick={collapse} edge="end">
                {!isCollapsed ? <ExpandLess htmlColor={active ? "#FFF" : undefined} /> : <ExpandMore htmlColor={active ? "#FFF" : undefined} />}
              </IconButton>
            </ListItemSecondaryAction>}
          </ListItem> </Link> : null
      }

      {
        hasChildren ? (
          <Collapse in={!isCollapsed}
            timeout="auto"
            unmountOnExit
          >
            <List disablePadding style={{ paddingLeft: 10 }} >
              {items.map((item, index) => (
                <TreeNode
                  key={item.url + index.toString()}
                  setCollapsed={setCollapsed}
                  collapsed={collapsed}
                  {...item}
                />
              ))}
            </List>
          </Collapse>
        ) : null
      }
    </>
  );
};

export default TreeNode;
