import React, { useState, useContext } from "react"
import { AuthContext } from "context/authContext"
import { Link } from "utils/link"
import { SignForm, TextField, Label, Grid, Spinner } from 'components/common'
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },


  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));

export const SignInForm = () => {
  const { handleLogin } = useContext(AuthContext)
  const classes = useStyles();

  const [state, setState] = useState({
    email: ``,
    password: ``,
  })

  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    return handleLogin(state)
  }

  return (

    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address or Username"
        name="email"
        value={state.email}
        onChange={handleUpdate}
        placeholder="Enter your username or email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        value={state.password}
        onChange={handleUpdate}
        name="password"
        label="Password"
        type="password"
        id="password"
        placeholder="Enter your password"
        autoComplete="current-password"
      />
      {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me" 
        />*/}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.submit}
      >
        Sign In
            </Button>


    </form>
  );
};

    // <SignForm>
    //   {loading ? <Spinner /> : <Grid container spacing={2}>
    //     <TextField
    //       data-testid="username-input"
    //       label="Email or Username"
    //       name="email"
    //       value={state.email}
    //       onChange={handleUpdate}
    //       margin="normal"
    //       placeholder="Enter your username or email"
    //     />
    //     <TextField
    //       data-testid="password-input"
    //       label="Password"
    //       type="password"
    //       name="password"
    //       value={state.password}
    //       onChange={handleUpdate}
    //       margin="normal"
    //       placeholder="Enter your password"
    //     />
    //     <Grid item xs={12}>
    //       <Label color="secondary" style={{ textAlign: 'center' }}><Link to="/forgotpassword">Forgot your password?</Link></Label>
    //     </Grid>
    //     <Grid center="true" item xs={12}>
    //       <Button
    //         data-testid="sign-in-button"
    //         type="submit"
    //         color="primary"
    //         variant="contained"
    //         onClick={handleSubmit}
    //       >
    //         Sign in
    //     </Button>
    //     </Grid>
    //   </Grid>}
    // </SignForm>