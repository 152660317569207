import React, { useState } from 'react';
import config from 'config';
import TreeNode from './treeNode';
import { useLocation } from "react-router-dom";

const defaultCollapsed = {};

const recursiveSortCollapse = (items, location) => {
  if (items) {
    //sort by order number
    items.sort((a, b) => parseInt(a.order) - parseInt(b.order))

    return items.forEach(item => {
      //uncolapse from url
      location && location.pathname.includes(item.slug) ?
        defaultCollapsed[item.slug] = false :
        defaultCollapsed[item.slug] = true;
      //recursion  
      if (item.items.length > 0) {
        //sort alphabetically by label
        //   item.items.sort((a, b) => (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0)
        recursiveSortCollapse(item.items)
      }
    })
  }
}
const Tree = ({ edges, location }) => {
  // edges.items.sort((a, b) => parseInt(a) - parseInt(b))
  recursiveSortCollapse(edges.items, location)

  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggle = slug => {
    setCollapsed({
      ...collapsed,
      [slug]: !collapsed[slug],
    });
  };

  return edges.items ? (
    <TreeNode
      // className={`${config.sidebar.frontLine ? 'showFrontLine' : 'hideFrontLine'} firstLevel`}
      setCollapsed={toggle}
      location={location}
      collapsed={collapsed}
      {...edges}
    />) : (<p>no content</p>)
};

export default Tree;
