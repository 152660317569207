
import React, { useState, createContext, useEffect, useContext } from "react"
import useFetch from "utils/useFetch"
import useTokens from 'utils/useTokens'
import config from 'config';
import { AuthContext } from "context/authContext"

const defaultContext = {
    tree: {},
    md: {},
    error: null,
    success: null,
    loading: false,
    getTree: () => {
        throw new Error('getTree() not implemented')
    },
    getMd: () => {
        throw new Error('getMd() not implemented')
    },
    // searchFaqs: () => {
    //     throw new Error('searchFaqs() not implemented')
    // },
    // updateFaq: () => {
    //     throw new Error('updateFaq() not implemented')
    // },
}


export const ContentContext = createContext(defaultContext)

export const ContentProvider = ({
    children
}) => {

    const { user } = useContext(AuthContext)

    const [md, setMd] = useState({})

    const [tree, setTree] = useState({})

    const [error, setError] = useState()

    const [success, setSuccess] = useState()

    const [loading, setLoading] = useState()



    useEffect(() => {
        const timer = setTimeout(() => {
            setError()
        }, 5000);

        return () => clearTimeout(timer);
    }, [error]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess()
        }, 5000);

        return () => clearTimeout(timer);
    }, [success]);
    useEffect(() => {
        if (user)
            getTree()
    }, [user]);

    const { fetchData } = useFetch()
    const { getTokens } = useTokens()


    const getTree = async () => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        setLoading(true)
        if (token) {
            try {

                const res = await fetchData(
                    `${config.serverURL}/api/content/tree`,
                    "GET",
                    null,
                    true,
                    {},
                    token

                )

                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        setTree(data)

                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    const getMd = async (filepath) => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            setLoading(true)
            setMd({})
            try {

                const res = await fetchData(
                    `${config.serverURL}/api/content/${filepath}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )


                setLoading(false)
                if (res.ok) {
                    const data = await res.json()
                    setMd(data)
                }
                else {
                    // setError(data)

                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    return (
        <ContentContext.Provider
            value={{
                tree,
                md,
                error,
                success,
                loading,
                getTree,
                getMd
            }}
        >
            {children}
        </ContentContext.Provider>
    )
}


