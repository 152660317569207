import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from "context/authContext"
import { FaqContext } from "context/faqContext"
import { Edit as EditIcon } from '@material-ui/icons';
import Faq from 'components/faqs/faqItem'
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, SwitchLabel } from 'components/common';
import styled from 'styled-components';
import { useParams, useHistory } from "react-router-dom";

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
export const FaqPage = () => {
    const { user: loggedUser } = useContext(AuthContext)
    let history = useHistory();
    const isAdmin = loggedUser.role === 'admin' || false
    let { faqId } = useParams();
    const { faq, getFaq, setFaq } = useContext(FaqContext)

    useEffect(() => {
        getFaq(faqId)
    }, [])



    const [edit, setEdit] = useState(false)



    const handleClear = () => {
        setFaq(faq)
        setEdit(false)

    }
    const handleBack = () => {
        setEdit(false)
        setFaq()
        history.push('/faq')
    }

    const changeEdit = () => { setEdit(!edit) }

    return (
        <Container>
            {isAdmin && faq && <Grid item xs={12}>
                <Fab onClick={handleBack}
                    size="small" color="primary">
                    <ClearIcon htmlColor={"#FFF"} />

                </Fab>
                <SwitchLabel
                    checked={edit}
                    onChange={changeEdit}
                    label="Edit"
                    icon={<EditIcon color={edit ? "primary" : "disabled"} />}
                />
            </Grid>}
            {/* {!faq && <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={TypeIcon('web')}
                >
                    Save
      </Button>
            </Grid>} */}
            {/* {!faq && faqs && faqs.map(faq => <FaqListItem key={faq.id} faq={faq} />)} */}
            {faq && <Faq edit={edit} setEdit={setEdit} faq={faq} handleClear={handleClear} />}
            {/*    <Fab onClick={handleAdd} variant="extended"
                    color="primary">
                    Add Faq
        <AddIcon color="inherit" />
                </Fab>} */}
        </Container>)
}



