import styled from 'styled-components';



export const Card = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.palette.background};
  ${'' /* background: ${({ theme }) => (theme === 'light' ? '#fff' : '#181717')}; */}
  height: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
