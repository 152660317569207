import styled from 'styled-components';
import MaterialGrid from '@material-ui/core/Grid';

export const Grid = styled(({ color, ...otherProps }) => <MaterialGrid {...otherProps} />)`
 max-width: 1280px;
  width: 100%;
  ${props => props.center && { display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }};
  ${props => props.column && { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }};
`;

