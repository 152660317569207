
import React, { useState, createContext, useEffect, useContext } from "react"
import useFetch from "utils/useFetch"
import config from 'config';
import { AuthContext } from "context/authContext"
import useTokens from 'utils/useTokens'

const defaultContext = {
    news: [],
    error: null,
    success: null,
    loading: false,
    getNews: () => {
        throw new Error('getNews() not implemented')
    },
    searchNews: () => {
        throw new Error('searchNews() not implemented')
    },
    updateNews: () => {
        throw new Error('updateNews() not implemented')
    },
}


export const NewsContext = createContext(defaultContext)

export const NewsProvider = ({ children }) => {

    const { user } = useContext(AuthContext)
    const { getTokens } = useTokens()
    const [news, setNews] = useState(null)
    const [error, setError] = useState()
    const [addNews, setAddNews] = useState(null)

    const [success, setSuccess] = useState()

    const [loading, setLoading] = useState()



    useEffect(() => {
        const timer = setTimeout(() => {
            setError()
        }, 5000);

        return () => clearTimeout(timer);
    }, [error]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess()
        }, 5000);

        return () => clearTimeout(timer);
    }, [success]);

    const { fetchData } = useFetch()

    const getNews = async () => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        if (token) {
            setLoading(true)
            const sort = 'sort=-date'
            try {
                const res = await fetchData(
                    `${config.serverURL}/api/news?${sort}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )
                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        setNews(data)
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    const searchNews = async (query) => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            setLoading(true)

            try {
                const myHeaders = new Headers();

                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


                var urlencoded = new URLSearchParams();

                urlencoded.append("query", query);

                const res = await fetchData(
                    `${config.serverURL}/api/news/search`,
                    "POST",
                    urlencoded,
                    true,
                    {},
                    token
                )

                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        setNews(data)
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }



    const handleUpdateNews = async (newId, { title, md, date }) => {
        setLoading(true)

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {

            var urlencoded = new URLSearchParams();

            urlencoded.append("title", title);
            // urlencoded.append("md", decodeURI(md));
            urlencoded.append("md", md);
            urlencoded.append("date", date);


            try {
                const res = await fetchData(
                    newId ? `${config.serverURL}/api/news/${newId}` : `${config.serverURL}/api/news`,
                    newId ? 'PATCH' : 'POST',
                    urlencoded,
                    true,
                    {},
                    token
                )
                setLoading(false)
                const data = await res.json()

                if (res.ok) {
                    setSuccess('News update successful')
                    setAddNews(null)
                    getNews()
                }
                else {
                    setError(data.message)
                }
            } catch (err) {
                console.error(err)
            }
        }
    }
    const crudComment = async (method, newsId, comment, body, userId) => {
        const commentId = comment?._id || ''
        const commentUser = comment?.user.id || ''
        console.log('comment :>> ', comment);
        setLoading(true)
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            let urlencoded = new URLSearchParams();
            if (newsId) urlencoded.append("newsId", newsId);
            if (body) urlencoded.append("body", body);
            if (commentId) urlencoded.append("commentId", commentId);

            try {
                const res = await fetchData(
                    `${config.serverURL}/api/news/comment/${commentUser}`,
                    method,
                    urlencoded,
                    true,
                    {},
                    token
                )
                setLoading(false)
                const data = await res.json()
                if (res.ok) {
                    setSuccess(data.msg)
                    getNews()
                }
                else {
                    console.log(`data`, data.message)
                    setError(data.message)
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    const handleDeleteNews = async (newsId) => {
        setLoading(true)

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            try {
                const res = await fetchData(
                    `${config.serverURL}/api/news/${newsId}`,
                    'DELETE',
                    null,
                    true,
                    {},
                    token
                )
                setLoading(false)
                if (res.ok) {
                    setSuccess('News deleted')
                    getNews()
                }
                else {
                    setError('Error deleting news')
                }
            } catch (err) {
                console.error(err)
            }
        }
    }
    return (
        <NewsContext.Provider
            value={{

                news,
                setNews,
                addNews,
                setAddNews,
                crudComment,
                error,
                success,
                loading,
                getNews,
                searchNews,
                handleUpdateNews,
                handleDeleteNews
            }}
        >
            {children}
        </NewsContext.Provider>
    )
}


