import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Spinner } from 'components/common'
import { Link } from 'utils/link'



export const GitHubRepos = ({ username }) => {

    const [repos, setRepos] = useState()
    useEffect(async () => {
        const res = await fetch(`https://api.github.com/users/${username}/repos`)
        const data = await res.json()
        setRepos(data)
    }, [])
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (<>
        <DialogTitle id="simple-dialog-title">GitHub Repositories</DialogTitle>
        <List>
            {repos ? repos.map((repo) => (
                <ListItem button key={repo.id}>

                    <Link to={repo.html_url}>

                        <ListItemText primary={repo?.name} />
                    </Link>
                </ListItem>
            )) : <p>Loading...</p>}

        </List>
    </>
    );
}