import React, { useState, useEffect, useContext } from 'react';
import { AdminContext } from "context/adminContext"
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Spinner } from 'components/common'
import { Link } from 'utils/link'



export const AddStudent = ({ cohortId }) => {
    const { students, getUsers, addStudent } = useContext(AdminContext)

    useEffect(async () => {
        if (!students) getUsers('Client')
    }, [])

    const handleClick = (student) => () => {
        addStudent(cohortId, student.id)
    }
    return (<>
        <DialogTitle id="simple-dialog-title">Users</DialogTitle>
        <List>
            {students ? students.map((user) => (
                <ListItem button button onClick={handleClick(user)} key={user.id}>



                    <ListItemText primary={`${user?.firstname} ${user?.lastname}`}
                        secondary={user?.cohort?.name}
                    />

                </ListItem>
            )) : <p>Loading...</p>}

        </List>
    </>
    );
}