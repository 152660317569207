import * as THREE from 'three'
import flatten from 'lodash/flatten'
import { SVGLoader as loader } from './SVGLoader'
// import night from 'assets/SVG/night.svg'
import city from 'assets/SVG/city2.svg'
// import morning from 'assets/SVG/morning.svg'
import tubes from 'assets/SVG/tubes2.svg'
// import woods from 'assets/SVG/woods.svg'
// import beach from 'assets/SVG/beach.svg'
// import beach2 from 'assets/SVG/beach2.svg'
// import landing from 'assets/SVG/landing.svg'


const doubleSide = THREE.DoubleSide
const deg = THREE.Math.degToRad
const colors = ['#21242d', '#8A2C00', '#0d4663', '#ffbcb7', '#2d4a3e', '#8bd8d2']
// const svgs = [night, city, morning, tubes, woods, beach]
const svgs = [city, tubes]
  // .map(name => `https://raw.githubusercontent.com/drcmda/react-three-fiber/master/examples/src/resources/images/svg/${name}.svg`)
  .map(
    url =>
      new Promise(resolve =>
        new loader().load(url, shapes =>
          resolve(flatten(shapes.map((group, index) => group.toShapes(true).map(shape => ({ shape, color: group.color, index })))))
        )
      )
  )

export { svgs, colors, deg, doubleSide }
