import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialSwitch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Grid } from 'components/common';


export const Switch = styled(MaterialSwitch)`
`;

export const SwitchLabel = ({ edit, checked, icon, onChange, name, persistant, label = "" }) => edit || persistant ? <FormControlLabel style={{ float: 'right' }}
    control={<Switch checked={checked} onChange={onChange} name={name} color="primary" />}
    label={icon ? icon : label}
/> :
    <Grid container justify="center">
        <Grid item xs={2}>
            <Typography>{checked ? 'Yes' : 'No'}</Typography>
        </Grid>
        <Grid item xs={2}>
            {icon}
        </Grid>
    </Grid>
