import styled, { css, keyframes } from 'styled-components';
import { ListItem as MuiListItem } from '@material-ui/core';


const inAnimation = keyframes`
 0% { opacity: 0  }
 100% {  opacity: 1; }
 `

export const ListItem = styled(MuiListItem)`
    position: relative;
    :hover {
      color: #fff;
      background-color: ${props => props.theme.palette.primary.main}
    }
      ${({ isactive, theme }) => isactive == "true" && css`
      background-color: ${theme.palette.primary.main};
      color: #fff;
      animation: ${inAnimation} .5s linear  ;
  `}

`;