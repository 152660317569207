import React, { useContext, useState } from 'react'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { makeStyles } from '@material-ui/core/styles';
import config from 'config';
import Fab from "@material-ui/core/Fab"
import { AdminContext } from 'context/adminContext'
import MaterialAvatar from '@material-ui/core/Avatar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        display: 'flex',
    },
    small: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
}))

export const ImgUpload = ({ id, type, srcImg = '', handleUploadImg, handleDeleteImg, size, alt = '' }) => {
    const [img, setImg] = useState((srcImg))
    const [uploadedLink, setUploadedLink] = useState()
    const { uploadFile, deleteFile } = useContext(AdminContext)
    const classes = useStyles();



    // const handleUpload = async (e) => {
    //     setImg('')
    //     const file = e.target.files[0]
    //     const link = await uploadFile(file, type, id, setImg)
    //     setUploadedLink(link)

    // }
    // const handleDeleteImg = (e) => {
    //     deleteFile(type, id)
    //     setImg('')
    // }


    return uploadedLink ? <MaterialAvatar src={uploadedLink} /> :
        <React.Fragment>
            {img !== '' ? <>
                <IconButton
                    onClick={handleDeleteImg}
                    name="img" color="primary" aria-label="upload picture" component="span">
                    <DeleteForeverIcon />
                </IconButton>
            </>
                :
                <>
                    <input
                        accept="image/*"
                        id="contained-button-file"
                        // multiple
                        type="file"
                        name="img"
                        onChange={handleUploadImg}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="contained-button-file">
                        <Fab component="span" size="large">
                            <AddPhotoAlternateIcon />
                        </Fab>
                    </label>
                </>}

        </React.Fragment>

}
