import React, { useState, useContext } from "react"
import { AuthContext } from "context/authContext"
import Typography from '@material-ui/core/Typography';
import { Container, Label, Grid } from 'components/common'
import Button from '@material-ui/core/Button';
import { Link } from "utils/link"
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const Unauthorized = () => {

    const { isAuthenticated } = useContext(AuthContext)



    return (
        <StyledContainer maxWidth="lg">
            <Grid container spacing={2}>

                {isAuthenticated ?
                    <Grid center="true" item xs={12}>
                        <Label style={{ textAlign: 'center' }}>It seems you are not authorized to be here!</Label>
                    </Grid> :
                    <>
                        <Grid center="true" item xs={12}>
                            <Label style={{ textAlign: 'center' }}>You should be authenticated to access this page!</Label>
                        </Grid>
                        <Grid center="true" item xs={12}>
                            <Button color="primary">
                                <Link to='/login'>Sign in!</Link>
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </StyledContainer>
    );
};

