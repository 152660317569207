import React, { useState, useContext } from "react"
import { AuthContext } from "context/authContext"
import Typography from '@material-ui/core/Typography';
import { SignForm, TextField, Spinner, Label, Grid } from 'components/common'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },


    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));
export const ResetPassword = ({ token }) => {
    const { handleResetPassword, loading } = useContext(AuthContext)

    const classes = useStyles();

    const [state, setState] = useState({
        password: ``,
        passwordRepeat: ``,
    })

    const handleUpdate = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        if (state.password !== state.passwordRepeat) return alert('Passwords do not match')
        else return handleResetPassword(state.password, token)
    }


    return (
        <form className={classes.form} noValidate>
            { token ? <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Label style={{ textAlign: 'center' }}>Select your new password</Label>

                </Grid>

                <Grid item xs={12}>
                    <TextField
                        data-testid="password-input"
                        label="Password"
                        type="password"
                        name="password"
                        required
                        value={state.password}
                        onChange={handleUpdate}
                        autoComplete="off"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        data-testid="password-confirm-input"
                        label="Confirm password"
                        type="password"
                        name="passwordRepeat"
                        required
                        value={state.passwordRepeat}
                        onChange={handleUpdate}
                        autoComplete="off"
                        margin="normal"
                    />
                </Grid>
                <Grid center="true" item xs={12}>
                    <Button
                        data-testid="sign-in-button"
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                </Button>
                </Grid>

            </Grid> :
                <>
                    <Label>Invalid reset password link.</Label>
                    <Label> Please contact <a href="mailto:admin@codeacademyberlin.com">admin@codeacademyberlin.com</a>  </Label>
                </>
            }
        </form>
    );
};

