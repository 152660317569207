import React from 'react';
import MaterialAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, ImgUpload } from 'components/common'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton';
import config from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  img: {
    display: 'flex',
  },
  xs: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
}));


export const Avatar = ({ alt, src, id, size, srcImg, edit, handleUploadImg, handleDeleteImg, name, type }) => {
  const classes = useStyles();
  return <>
    { edit && <ImgUpload name="img" size={size} id={id} type={type} srcImg={srcImg} handleUploadImg={handleUploadImg} handleDeleteImg={handleDeleteImg} />}
    {/* <TextField label="Image URL" name={name} value={src} edit={edit} handleUpdate={handleUpdate} /> */}

    <MaterialAvatar alt={alt} className={classes[size]} src={srcImg ? srcImg : src} />

    {/* <MaterialAvatar alt={alt} className={classes[size]} src={srcImg ? `${config.serverURL}${srcImg}` : src} /> */}
  </>
}

