import React, { useContext } from "react"
import { AuthContext } from "../context/authContext"
import { StyledHeading } from '../components/styles/Docs';
import { courseString } from "utils/definitions"

export const CalendarPage = () => {
    const { user } = useContext(AuthContext)
    const calendar = user?.cohort?.calendars || null
    console.log('user :>> ', user);

    return (
        <>
            <StyledHeading>Calendar</StyledHeading>
            {calendar ?
                <> {user.course && <p>{user.cohort.name}:{courseString[user.course]}</p>}
                    <iframe title="Calendar" width="100%" height={`${window.innerHeight - 200}px`} frameBorder="0" scrolling="no"
                        style={{ border: 0 }}
                        src={`https://calendar.google.com/calendar/embed?src=${calendar[user.course]}&ctz=Europe%2FBerlin&bgcolor=%2300897d&showTitle=0&showPrint=0`}></iframe>

                </>
                : <p>No calendar available</p>
            }
        </>)
}

