import MaterialTextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const TextField = styled(MaterialTextField)`
  width: 100%;
  /* width: 50ch; */
  position: relative;
padding-right : ${props => `${props.theme.spacing(1)}px`};
padding-left : ${props => `${props.theme.spacing(1)}px`};

  > div::before {
    border-color: ${props => props.theme.palette.primary};
  }
  > div::after {
    border-color: ${props => props.theme.palette.primary};
  }

  input {
    color: ${props => props.theme.palette.text};

    &::placeholder {
      color: ${props => props.theme.palette.text};
    }
  }

  label {

    color: ${props => props.theme.palette.text};

  }
`;
