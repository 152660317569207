import React, { useState, useContext, useEffect, useMemo } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { FaqContext } from "context/faqContext"
import CodeIcon from '@material-ui/icons/Code';
import InfoIcon from '@material-ui/icons/Info';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router-dom'
import FaqListItem from './faqListItem'

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
}));

const TypeIcon = ({ type }) => {
    const classes = useStyles();

    switch (type) {
        case 'web':
            return <CodeIcon fontSize='large' className={classes.icon} />
        case 'data':
            return <DataUsageIcon fontSize='large' className={classes.icon} />
        default:
            return <InfoIcon fontSize='large' className={classes.icon} />
    }
}

const SearchFaqs = () => {

    const { faqs, searchFaqs, loading } = useContext(FaqContext)

    const [value, setValue] = useState(null);

    const [inputValue, setInputValue] = useState('');

    const [options, setOptions] = useState([]);

    let history = useHistory();


    const fetch = useMemo(
        () =>
            throttle((request) => {
                searchFaqs(request.input)
            }, 200),
        [],
    );

    useEffect(() => {
        setOptions(faqs)
    }, [faqs])
    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        else if (inputValue.length >= 3) {
            fetch({ input: inputValue }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            style={{ width: '100%' }}
            getOptionLabel={(option) => option.title}
            filterOptions={(x) => x}
            options={options}
            loading={loading}
            // autoComplete
            // includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (newValue) {
                    history.push(`/faq/${newValue.id}`)
                    // setFaq(newValue)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Search...(at least 3 characters)" variant="outlined" fullWidth />
            )}
            renderOption={(option) => {


                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <TypeIcon type={option?.type} />
                        </Grid>

                        <Grid item xs>
                            <Typography variant="h6" color="textSecondary">
                                {option.title}
                            </Typography>
                            {option.tags.map((tag, index) => (
                                <Chip key={index} label={tag} />
                            ))}


                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

export default SearchFaqs
