import React, { useState } from 'react'
import { TextField, Label } from 'components/common';

export const TextFieldLabel = ({ title, type = '', placeholder = '', name = '', label = '', value = '', edit, handleUpdate }) => {
  // const [value, setValue] = useState
  return edit ? (<TextField
    data-testid="username-input"
    label={label}
    placeholder={placeholder}
    name={name}
    type={type}
    value={value}
    onChange={handleUpdate}
    autoComplete="off"
    margin="normal"
  />) : (<>
    {label && <Label variant="subtitle2" gutterBottom>
      {label}
    </Label>}
    <Label variant={title ? "h2" : "subtitle1"} >
      {value}
    </Label>
  </>)
}
