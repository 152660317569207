
const config = {
  admin: ['/calendar/', '/admin/'],
  serverURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://caberlin-lms-v3.herokuapp.com',
  header: {
    logo: ``,
    logoLink: '/',
    title: 'LMS',
    githubUrl: 'https://github.com/CodeAcademyBerlin',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [],
  },
  siteMetadata: {
    title: 'Code Academy Berlin | LMS',
    description: '',
    ogImage: null,
    docsLocation: 'https://github.com/CodeAcademyBerlin/content/tree/master',
    favicon: 'src/assets/favicon32.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Code Academy Berlin',
      short_name: 'CA Berlin',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#00897D',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
