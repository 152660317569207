
import React, { useState, useEffect, createContext } from 'react'
import useFetch from 'utils/useFetch'
import useTokens from 'utils/useTokens'
import { updateUserKeys } from "utils/definitions"
import { pick } from 'utils/pick'
import config from 'config';
import { Redirect } from "react-router-dom";

const defaultContext = {
    isAuthenticated: false,
    user: null,
    error: null,
    success: null,
    loading: false,
    appLoading: true,
    handleLogin: () => {
        throw new Error('handleLogin() not implemented')
    },
    handleRegister: () => {
        throw new Error('handleRegister() not implemented')
    },
    handleUpdateUser: () => {
        throw new Error('handleUpdateUser() not implemented')
    },
    handleForgotPassword: () => {
        throw new Error('handleForgotPassword() not implemented')
    },
    handleResetPassword: () => {
        throw new Error('handleResetPassword() not implemented')
    },
    logout: () => { },
}




const getUserId = () => window.localStorage.caBerlinUserId
    ? JSON.parse(window.localStorage.caBerlinUserId)
    : null

export const AuthContext = createContext(defaultContext)

export const AuthProvider = ({
    children
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const [user, setUser] = useState(null)

    const [error, setError] = useState()

    const [success, setSuccess] = useState()

    const [loading, setLoading] = useState()

    const [appLoading, setAppLoading] = useState(true)

    const { fetchData } = useFetch()
    const { getTokens } = useTokens()

    useEffect(() => {
        getAuthentication()
    }, [])
    useEffect(() => {
        const timer = setTimeout(() => {
            setError()
        }, 5000);

        return () => clearTimeout(timer);
    }, [error]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess()
        }, 5000);

        return () => clearTimeout(timer);
    }, [success]);

    const setLocalStorage = (data, key) => (window.localStorage[key] = JSON.stringify(data))

    const clearLocalStorage = () => {
        localStorage.removeItem('caBerlinTokens')
        localStorage.removeItem('caBerlinUserId')
    }


    const onLoginSuccess = (serverResponse) => {

        setLocalStorage(serverResponse.tokens, 'caBerlinTokens')
        // setLocalStorage(serverResponse.use r.id, 'caBerlinUserId')
        setUser(serverResponse.user)
        setIsAuthenticated(true)
    }


    const getUser = async (userId) => {
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        if (token && userId) {
            const url = user.role === 'admin' || userId === user.id ? '' : 'basic'

            setLoading(true)
            try {
                const res = await fetchData(
                    `${config.serverURL}/api/users/${url}/${userId}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )

                setLoading(false)
                if (res.error) {
                    console.log('res.err :>> ', res.err);
                    // setError(res.error)
                }
                else {
                    if (res.ok) {
                        const user = await res.json()
                        console.log('user :>> ', user);
                        return user

                    }

                }

            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
        setAppLoading(false)

    }
    const getAuthentication = async () => {
        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        if (token) {
            setLoading(true)
            try {
                const res = await fetchData(
                    `${config.serverURL}/api/users/auth`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )
                if (res.error) {
                    console.log('res.err :>> ', res.err);
                    // setError(res.error)
                }
                else {
                    if (res.ok) {
                        const user = await res.json()
                        console.log('user :>> ', user);

                        setUser(user)
                        setIsAuthenticated(true)
                        setSuccess('Retrieved user data')
                    }
                    else {
                        setUser(null)
                        setIsAuthenticated(false)
                        // navigate('/login')
                    }
                }
                setLoading(false)
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
        setAppLoading(false)

    }

    const handleLogin = async ({ email, password }) => {
        setLoading(true)

        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            urlencoded.append("email", email);
            urlencoded.append("password", password);

            var requestOptions = {
                headers: myHeaders,
                redirect: 'follow'
            };

            const res = await fetchData(
                `${config.serverURL}/api/auth/login`,
                "POST",
                urlencoded,
                false,
                requestOptions
            )

            if (res.error) setError(res.error.message)
            else {
                const json = await res.json()


                if (res.ok) {
                    onLoginSuccess(json)
                    setSuccess(`Welcome back ${json.user?.firstname || ''}!`)
                }
                else setError(json.message)
            }

            setLoading(false)

        } catch (err) {
            console.error(err)
        }
    }
    const isUser = async (email) => {
        setLoading(true)

        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            urlencoded.append("email", email);

            var requestOptions = {
                headers: myHeaders,
                redirect: 'follow'
            };

            const res = await fetchData(
                `${config.serverURL}/api/users/isUser`,
                "POST",
                urlencoded,
                false,
                requestOptions
            )
            setLoading(false)
            if (res.error) setError(res.error.message)
            else {
                const json = await res.json()


                if (res.ok) {
                    json?.firstname && setSuccess(`Hi ${json.firstname}! Let's finish your registration.`)

                    return json
                }
                else setError(json.message)
            }



        } catch (err) {
            console.error(err)
        }
    }

    const handleForgotPassword = async ({ emailOrUsername }) => {
        setLoading(true)

        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            urlencoded.append("emailOrUsername", emailOrUsername);

            var requestOptions = {
                headers: myHeaders,
                redirect: 'follow'
            };

            const res = await fetchData(
                `${config.serverURL}/api/auth/forgot-password`,
                "POST",
                urlencoded,
                false,
                requestOptions
            )


            if (res.error) setError(res.error)
            else {
                const json = await res.json()


                if (res.ok) {
                    setSuccess(json.message)
                    // navigate('/')

                }
                else setError(json.message)
            }

            setLoading(false)

        } catch (err) {
            console.error(err)
        }
    }

    const handleResetPassword = async (password, token) => {
        setLoading(true)
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            urlencoded.append("password", password);


            var requestOptions = {
                headers: myHeaders,
                redirect: 'follow'
            };

            const res = await fetchData(
                `${config.serverURL}/api/auth/reset-password?token=${token}`,
                "POST",
                urlencoded,
                false,
                requestOptions
            )

            if (res.error) setError(res.error.message)
            else {
                const json = await res.json()

                if (res.ok) {
                    setSuccess(json.message)
                    // navigate('/login')
                }
                else setError(json.message)
            }
            setLoading(false)

        } catch (err) {
            console.error(err)
        }
    }

    const handleRegister = async ({ email, password, username, photoConsent }) => {
        setLoading(true)
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            urlencoded.append("email", email);
            urlencoded.append("password", password);
            urlencoded.append("username", username);
            urlencoded.append("photoConsent", photoConsent);

            var requestOptions = {
                headers: myHeaders,
                redirect: 'follow'
            };

            const res = await fetchData(
                `${config.serverURL}/api/auth/register`,
                "POST",
                urlencoded,
                false,
                requestOptions
            )

            if (res.error) setError(res.error.message)
            else {
                const json = await res.json()

                if (res.ok) {
                    setSuccess('Registration successful')
                    onLoginSuccess(json)
                }
                else setError(json.message)
            }
            setLoading(false)

        } catch (err) {
            console.error(err)
        }
    }

    const handleUpdateUser = async (userId, state) => {
        setLoading(true)
        console.log('state :>> ', state);
        try {
            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();

            const body = pick(state, updateUserKeys()[user.role]);

            Object.keys(body).forEach(key => urlencoded.append(key, state[key]))
            const tokenObj = await getTokens()
            const token = tokenObj?.access?.token

            const res = await fetchData(
                `${config.serverURL}/api/users/${userId}`,
                "PATCH",
                urlencoded,
                true,
                {},
                token
            )

            if (res.error) setError(res.error.message)
            else {
                const json = await res.json()

                if (res.ok) {
                    setSuccess('User update successful')
                    getUser()

                }
                else setError(json.message)
            }
            setLoading(false)

        } catch (err) {
            console.error(err)
        }
    }

    const logout = async () => {
        try {

            const myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            const tokensOject = JSON.parse(window.localStorage.caBerlinTokens)

            const refreshToken = tokensOject?.refresh?.token || null
            if (refreshToken) {
                urlencoded.append("refreshToken", refreshToken)

                var requestOptions = {
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const res = await fetchData(
                    `${config.serverURL}/api/auth/logout`,
                    "POST",
                    urlencoded,
                    false,
                    requestOptions
                )
            }

            setIsAuthenticated(false)
            clearLocalStorage()
            setUser(null)
            // navigate('/login')

        }
        catch (err) {
            console.log('err :>> ', err);
        }
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                error,
                success,
                loading,
                appLoading,
                getUser,
                handleLogin,
                handleRegister,
                handleUpdateUser,
                handleForgotPassword,
                handleResetPassword,
                logout,
                isUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}


