import React from 'react';
import { Link } from 'utils/link';
import { StyledNextPrevious } from '../styles/PageNavigationButtons';
import { Spinner, Grid } from 'components/common'
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const NextPrevious = ({ prev, next }) => {


  return (
    <Grid container center="true">
      <Grid center="true" item xs={6}>  {prev &&
        <Link to={`/content/${prev}`}>
          <Fab style={{ width: 150 }} size="large" variant="extended" color="primary" aria-label="add" >
            <NavigateBeforeIcon htmlColor="#FFF" />
            {prev.split('/').pop()}
          </Fab>
        </Link>
      }</Grid>

      <Grid center="true" item xs={6}>
        {next &&
          <Link to={`/content/${next}`} >
            <Fab style={{ width: 150 }} size="large" variant="extended" color="primary" aria-label="add" >
              {next.split('/').pop()}
              <NavigateNextIcon htmlColor="#FFF" />
            </Fab>
          </Link>}
      </Grid>
    </Grid>
  );
};

export default NextPrevious;
