import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { NavMenu } from './NavMenu';
import { Spinner } from 'components/common';
import { useLocation, Link } from "react-router-dom";
import config from 'config';
import styled from 'styled-components';
import Alerts from './Alerts'
import Sidebar from '../sidebar';
import { AuthContext } from "context/authContext"
import { courseString } from "utils/definitions"
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const defaultDrawerWidth = 240;
const minDrawerWidth = 0;

// const maxDrawerWidth = 500;


const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: 55,
        // scrollPaddingTop: 55,
        display: 'flex',
        height: '100%',
        minHeight: '100vh'
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'none',
        fontFamily: 'Alenia',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    link: {
        color: "#FFFFFF",
        textDecoration: 'none',
        paddingLeft: 20
    },
    appBar: {
        color: "#FFFFFF",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: -drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        flexShrink: 0,
    },
    toolbar: {
        // theme.mixins.toolbar
    },
    dragger: {
        width: "5px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "#f4f7f9"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,

        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const StyledAppBar = styled(AppBar)`

height: 55px;
min-height: 55px;
color: #fff;
justify-content: center;
       ${({ theme }) => `
zIndex: ${theme.zIndex.drawer + 1},
         transition:  ${theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
})}`
    }
`
const Main = styled.div`
     ${'' /* display: flex;
     justify-content: space-around;
     flex-direction: column;
    align-items: center; */}
    width: 100%;
/* padding-right: 2rem;  */
margin-top: ${({ isAuthenticated }) => isAuthenticated ? `55px` : '0px'};
    /* min-height: calc(100vh - 50px ); */
    margin-left: ${({ drawerWidth, theme }) => `${drawerWidth}px`};
    ${({ theme }) => `
         transition:  ${theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    /* marginRight: theme.spacing(1), */
    ...theme.mixins.toolbar
}
)}`
    }
`


const CourseItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${props => props.theme.palette.text}
`;



export const Layout = ({ children, rightSidebar }) => {
    const classes = useStyles();

    let location = useLocation();

    const theme = useTheme();

    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const { user, isAuthenticated } = useContext(AuthContext)


    const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);

    //  For Draggable drawer feature
    // const handleMouseDown = () => {
    //     document.addEventListener("mouseup", handleMouseUp, true);
    //     document.addEventListener("mousemove", handleMouseMove, true);
    // };

    // const handleMouseUp = () => {
    //     document.removeEventListener("mouseup", handleMouseUp, true);
    //     document.removeEventListener("mousemove", handleMouseMove, true);
    // };

    // const handleMouseMove = useCallback(e => {
    //     const newWidth = e.clientX - document.body.offsetLeft;

    //     if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
    //         setDrawerWidth(newWidth);
    //     }
    //     if (newWidth < minDrawerWidth) {
    //         handleDrawerClose()
    //     }
    // }, []);

    const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

    let searchIndices = [];

    if (isSearchEnabled && config.header.search.indexName) {
        searchIndices.push({
            name: `${config.header.search.indexName}`,
            title: `Results`,
            hitComp: `PageHit`,
        });
    }

    // const LoadableComponent = Loadable({
    //     loader: () => import('../search/index'),
    //     loading: LoadingProvider,
    // });

    const handleDrawerOpen = () => {
        // setOpen(true);
        // if (drawerWidth === 0)
        setDrawerWidth(defaultDrawerWidth)
    };

    const handleDrawerClose = () => {
        // setOpen(false);
        setDrawerWidth(smUp ? minDrawerWidth : 0)

    };




    return (
        <div className={classes.root}>
            <CssBaseline />

            {isAuthenticated && <StyledAppBar
                // position="fixed"
                style={{ left: isAuthenticated ? drawerWidth : 0, width: 'auto', color: "#FFFFFF", }}
            // PaperProps={{ style: { left: drawerWidth } }}
            // className={classes.appBar}
            >

                <Toolbar >
                    {isAuthenticated && <IconButton onClick={drawerWidth === minDrawerWidth ? handleDrawerOpen : handleDrawerClose}>
                        {drawerWidth === minDrawerWidth ? <MenuIcon htmlColor="#FFF" /> : <MenuOpenIcon htmlColor="#FFF" />}
                    </IconButton>}
                    <Link to="/">
                        {/* <img alt="brand" src={NewSymbol} width="60px" /> */}
                        <Spinner fill="#fff" size="40px" />
                    </Link>
                    <Link to="/" className={classes.link}>
                        <Typography className={classes.title} variant="subtitle1" noWrap>
                            LMS | Code Academy Berlin
                    </Typography>
                    </Link>
                    {isSearchEnabled && isAuthenticated ? (
                        {/* <LoadableComponent collapse={true} indices={searchIndices} /> */ }
                    ) : null}
                    <div className={classes.grow} />
                    <NavMenu />
                </Toolbar>
            </StyledAppBar>}
            <div className={classes.toolbar} />
            {isAuthenticated && <Drawer
                className={classes.drawer}
                variant={smUp ? "persistent" : "temporary"}
                anchor="left"
                open={drawerWidth > 0}
                onClose={handleDrawerClose}
                PaperProps={{ style: { width: drawerWidth } }}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {/* <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} /> */}
                <div className={classes.drawerHeader}>
                    {drawerWidth > 200 && (
                        <CourseItem >
                            {/* <div className='greenCircle' /> */}
                            <h3>{courseString[user?.course]}</h3>
                        </CourseItem>
                    )}

                </div>
                <Divider />
                <Sidebar location={location} handleDrawerOpen={handleDrawerOpen} drawerWidth={drawerWidth} />
            </Drawer>}

            <Main isAuthenticated={isAuthenticated} drawerWidth={isAuthenticated && smUp ? drawerWidth : 0}>
                <Alerts />
                {children}
            </Main>
        </div>
    );
}
