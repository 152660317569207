import React from 'react';
import { Avatar, TextFieldLabel, DropDownLabel, DatePickerLabel, SwitchLabel } from 'components/common';
import PropTypes from 'prop-types';
import { Link } from 'utils/link';


const CustomTableCell = ({ row, name, dataTypes, onChange, tableType }) => {
    const { isEditMode } = row;

    switch (dataTypes[name]?.type) {
        case 'string':
            return <TextFieldLabel name={name} value={row[name]} edit={isEditMode}
                handleUpdate={e => onChange(e, row, name)}
            />;
        case 'link':
            return !dataTypes[name]?.link ?
                <TextFieldLabel name={name}
                    value={isEditMode ? row[name] : <Link color="primary" to={row[name]}>{row[name]}</Link>}
                    edit={isEditMode}
                    handleUpdate={e => onChange(e, row, name)} /> :
                <TextFieldLabel name={name}
                    value={isEditMode ? row[name] : <Link color="primary" to={dataTypes[name]?.link.replace('<link_value>', row[name])}>{row[name]}</Link>}
                    edit={isEditMode}
                    handleUpdate={e => onChange(e, row, name)} />
        case 'date':
            return <DatePickerLabel format={0} name={name} value={row[name]} edit={isEditMode}
                handleUpdate={e => onChange(e, row)} />;
        case 'dropdown':
            return <DropDownLabel name={name} edit={isEditMode} handleUpdate={e => onChange(e, row, name)} options={dataTypes[name].options} value={row[name]} />
        case 'avatar':
            return <Avatar type={tableType} name={name} edit={false} id={row.id} srcImg={row.img} handleUpdate={e => onChange(e, row)} src={row.avatar} alt={row.firstname} size="small" />
        case 'boolean':
            return <SwitchLabel
                name={name}
                id={row.id}
                checked={row[name]}
                onChange={e => onChange(e, row, name)}
                label={row[name]} name={name} edit={isEditMode}
                edit={isEditMode}
            />
        default:
            return <TextFieldLabel name={name} value={row[name]} edit={isEditMode}
                handleUpdate={e => onChange(e, row)}
            />;
    }
};
export default CustomTableCell

CustomTableCell.propTypes = {
    dataTypes: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    tableType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}