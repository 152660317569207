import React from 'react'
import Typography from '@material-ui/core/Typography';
import { TextField, Label } from 'components/common';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import MaterialFormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
  position: relative;
    div {
        color: ${props => props.theme.palette.text};

  }
  label {
    color: ${props => props.theme.palette.text};
  } 
  `

export const DropDownLabel = ({ name = '', label = '', value, edit, handleUpdate, options }) => {
  const option = options?.find(option => option.value === value) || null

  return edit ? (<FormControl >
    <InputLabel id={label}>{label}</InputLabel>
    <Select
      label={label}
      name={name}
      value={value ? value : ''}
      defaultValue={value ? value : ''}
      onChange={handleUpdate}
    >
      {options && options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}

    </Select>
  </FormControl>
  ) : (<>
    <Label variant="subtitle2" gutterBottom>
      {label}
    </Label>
    <Label variant="subtitle1" gutterBottom>
      {option ? option.label : value}
    </Label>
  </>)
}
