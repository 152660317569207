import React, { useContext, useEffect } from "react";
import mdxComponents from "components/mdxComponents";
import MDX from "@mdx-js/runtime";
import { useParams } from "react-router-dom";
import { ContentContext } from "../context/contentContext";
import { StyledHeading } from "../components/styles/Docs";
import { Spinner, Grid } from "components/common";
import RightSidebar from "components/content/rightSidebar";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "utils/link";
import NextPrevious from "components/content/NextPrevious";

const RightSideBarWidth = styled("div")`
  display: block;
  width: 100%;
  max-width: 300px;
  justify-self: flex-end;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
const Wrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  width: 100%;

  padding-left: 20px;
  @media only screen and (max-width: 991px) {
    padding-right: 20px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
  }
`;
const Container = styled("div")`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  padding-top: 20px;
  width: 100%;

  padding-left: 20px;
  padding-bottom: 50px;

  @media only screen and (max-width: 767px) {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
  }
`;
const MdWrapper = styled("div")`
  margin-top: 50px;
  max-width: 900px;
  text-align: justify;
  /* min-width: 500px; */
  & > p {
    margin-bottom: 20px;
  }
  & > h2 {
    margin-bottom: 20px;
  }
`;
export const ContentPage = () => {
  const { getMd, md, loading } = useContext(ContentContext);

  let { filepath } = useParams();

  useEffect(() => {
    getMd(filepath);
  }, [filepath]);
  console.log("mdobject :>> ", md);
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        {filepath.split("/").map((path, i, arr) => (
          <Link
            to={`/content/${filepath.substring(
              0,
              filepath.indexOf(path) + path.length
            )}`}
            key={path}
          >
            {path}
          </Link>
        ))}
      </Breadcrumbs>
      <Wrapper>
        <div>
          {md.title && <StyledHeading>{md.title}</StyledHeading>}
          {loading && <Spinner size="50px" />}
          {/* {error && <p>{error}</p>} */}
          {!loading && md && (
            <MdWrapper>
              <MDX components={mdxComponents}>{md.body}</MDX>
            </MdWrapper>
          )}
        </div>
        <RightSideBarWidth>
          {md.headers && <RightSidebar headers={md.headers} />}
        </RightSideBarWidth>
      </Wrapper>
      <NextPrevious prev={md.prev} next={md.next} />
    </Container>
  );
};
