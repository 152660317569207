import CodeIcon from '@material-ui/icons/Code';
import InfoIcon from '@material-ui/icons/Info';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
}));

export const TypeIcon = ({ type }) => {
    const classes = useStyles();

    switch (type) {
        case 'web':
            return <CodeIcon fontSize='large' className={classes.icon} />
        case 'data':
            return <DataUsageIcon fontSize='large' className={classes.icon} />
        default:
            return <InfoIcon fontSize='large' className={classes.icon} />
    }
}