// import React, { useContext, useEffect } from "react"
// import { AuthContext } from "../context/authContext"
// import { Profile } from 'components';
// import { Container, Label } from 'components/common';
// import { Unauthorized } from 'components/auth/Unauthorized'



// export const ProfilePage = () => {

//     const { user } = useContext(AuthContext);


//     return (
//         <Container maxWidth="lg">
//             {user ? <Profile user={user} /> :
//                 <Label variant="subtitle2" gutterBottom>
//                     No User
//                 </Label>}
//         </Container>
//     )
// }
import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from "context/authContext"
import { AdminContext } from "context/adminContext"
import { Edit as EditIcon } from '@material-ui/icons';
import { Cohort } from 'components';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Zoom from '@material-ui/core/Zoom';
import { Avatar, Grid, TextFieldLabel, SwitchLabel, DropDownLabel } from 'components/common';
import { Profile } from 'components';
import styled, { css, keyframes } from 'styled-components';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { TypeIcon } from 'components/common'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;


export const ProfilePage = () => {
    const { user: loggedUser, getUser } = useContext(AuthContext)
    let history = useHistory();
    let location = useLocation();
    const [user, setuser] = useState()

    const isAdmin = loggedUser.role === 'admin' || false
    let { userId } = useParams();
    const { cohort, setCohort } = useContext(AdminContext)


    useEffect(async () => {
        setuser()
        if (userId === 'me') {
            const user = await getUser(loggedUser.id)
            setuser(user)
        }
        else {
            const user = await getUser(userId)
            setuser(user)
        }
    }, [location])

    const handleBack = () => {
        setuser()
        // console.log('location.state :>> ', location.state);
        // let { from } = location.state || { from: { pathname: "/family" } };

        history.goBack();

    }


    return (
        <Container>
            {user && <Grid item xs={12}>
                <Fab size="small" color="primary" onClick={handleBack} >
                    <ArrowBackIosRoundedIcon htmlColor={"#FFF"} />
                </Fab>


            </Grid>}
            {user && <Profile user={user} />}
        </Container>)
}




