import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../context/authContext';
import { StyledHeading } from '../components/styles/Docs';
import { mentors } from 'utils/definitions';
import { Unauthorized } from 'components/auth/Unauthorized'


export const MentorsPage = () => {
  const { user } = useContext(AuthContext);

  const handleAccess = access => {
    if (user.course === 'all') return true;
    if (access === 'all') return true;
    else if (access === user.course) return true;
    else return false;
  };

  useEffect(() => {
    const head = document.querySelector('head');

    if (head) {
      const script = document.createElement('script');

      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
  }, []);


  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate(`/login`)
  //   }
  // }, [])
  return (<> <StyledHeading >Mentors</StyledHeading>
    <div
      id="schedule_form"
      style={{
        height: '100%',
        // width: '100%',
        display: 'flex',
        marginTop: '20px',
        //   //   flexDirection: 'column',
        //   //   alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
      }}
    >

      {mentors.map(mentor => {
        if (handleAccess(mentor.course)) {
          return (
            <div
              key={mentor.name}
              className="calendly-inline-widget"
              data-url={mentor.calendly}
              style={{ width: '400px', height: '100%', oveflow: 'hidden', marginRight: '30px' }}
            />
          );
        }
      })}
    </div>
  </>)
};

