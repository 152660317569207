import React, { useContext, useEffect } from 'react'
import { AuthContext } from "context/authContext"

import { NewsContext } from "context/newsContext"

import NewsItem from './NewsItem'

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import { Grid } from 'components/common';

import styled from 'styled-components';


// const Container = styled('div')`
//   display: flex;
//   flex-direction: column;
//    align-items: center;
//   justify-content: space-between;
//   max-width: 80%
//   /* padding: 20px; */
//   /* width: 100%; */
//   @media only screen and (max-width: 767px) {
//     padding: 0px;
//   }
// `;
export const News = () => {
    const { user: loggedUser } = useContext(AuthContext)

    const isAdmin = loggedUser.role === 'admin' || false
    const { news, getNews, addNews, setAddNews } = useContext(NewsContext)

    useEffect(() => {
        getNews()
    }, [])



    const handleAdd = () => {
        setAddNews({ title: 'News Title', md: '', date: new Date() })
    }

    const handleCancel = () => {
        setAddNews(null)

    }


    return (
        <Container maxWidth="md">
            {isAdmin && <Grid item xs={12}>
                {addNews ?
                    <NewsItem news={addNews} add handleCancel={handleCancel} /> :
                    <Fab onClick={handleAdd} color="primary" aria-label="add">
                        <AddIcon htmlColor="#FFF" />
                    </Fab>}
            </Grid>}
            <Grid item xs={12}>
                {news && news.map(n => <NewsItem key={n.id} news={n} handleCancel={handleCancel} />)}
            </Grid>
        </Container>)
}



