import React, { useState, useContext } from 'react'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab"
import { AdminContext } from 'context/adminContext'
import MDEditor, { commands, ICommand, TextState, TextApi } from '@uiw/react-md-editor';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WebIcon from '@material-ui/icons/Web';
import MaterialAvatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import BackupIcon from '@material-ui/icons/Backup';
import LinkIcon from '@material-ui/icons/Link';
import TitleIcon from '@material-ui/icons/Title';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
const youtube = {
    name: 'Youtube',
    keyCommand: 'youtube',
    buttonProps: { 'aria-label': 'Insert video id' },
    icon: (
        <YouTubeIcon />
    ),
    execute: (state, api) => {
        let modifyText = `<youtube title="video title" height="500px" link="${state.selectedText}"/>`;
        if (!state.selectedText) {
            modifyText = `<youtube title="video title" height="500px" link="videoID"/>`;
        }
        api.replaceSelection(modifyText);
    },
};
const iframe = {
    name: 'Iframe',
    keyCommand: 'iframe',
    buttonProps: { 'aria-label': 'Insert video id' },
    icon: (
        <WebIcon />
    ),
    execute: (state, api) => {
        let modifyText = `<embeddediframe title="CAB-map" height="500px" link="{state.selectedText}"/>`;
        if (!state.selectedText) {
            modifyText = `<embeddediframe title="CAB-map" height="500px" link="iframe url"/>`;
        }
        api.replaceSelection(modifyText);
    },
};
const imageLink = {
    name: 'Image Link',
    keyCommand: 'imgLink',
    buttonProps: { 'aria-label': 'Insert Img link' },
    icon: (
        <LinkIcon />
    ),
    execute: (state, api) => {
        let modifyText = `<img width="100%" src ="${state.selectedText}" />`;
        if (!state.selectedText) {
            modifyText = `<img width="100%" src ="insert image link here" />`;
        }
        api.replaceSelection(modifyText);
    },
};



export const Markdown = ({ md, handleUpdateMd }) => {
    const [upload, setUpload] = useState(new Date().valueOf())
    const [uploadedLink, setUploadedLink] = useState()
    const { uploadFile, deleteFile, loading } = useContext(AdminContext)

    const handleUpload = async (e) => {
        const file = e.target.files[0]
        const link = await uploadFile(file, 'content', upload)
        setUploadedLink(link)

    }
    return (
        <>
            <MDEditor
                value={md}
                onChange={handleUpdateMd}
                commands={[
                    commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
                        name: 'title',
                        groupName: 'title',
                        buttonProps: { 'aria-label': 'Insert title' },
                        icon: (
                            <TitleIcon />
                        )
                    }),
                    youtube,
                    iframe,
                    imageLink,
                    commands.group([], {
                        name: 'update',
                        groupName: 'update',
                        icon: (
                            <AddPhotoAlternateIcon />
                        ),
                        children: ({ close, execute, getState, textApi }) => {
                            const handleExecute = () => {
                                execute()
                                handleClose()
                            }

                            const handleClose = () => {
                                setUploadedLink()
                                setUpload(new Date().valueOf())
                                close()
                            }
                            return (
                                <div style={{ display: 'flex', padding: 10 }}>
                                    { uploadedLink ? <MaterialAvatar src={uploadedLink} size="large" /> : <>
                                        <input
                                            accept="image/*"
                                            id="contained-button-file"
                                            // multiple
                                            type="file"
                                            name="img"
                                            onChange={handleUpload}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Fab component="span" size="large">
                                                <BackupIcon />
                                            </Fab>
                                        </label>
                                    </>}
                                    {loading && <CircularProgress color="primary" />}

                                    <div>
                                        <Button type="button" onClick={handleClose}>Clear</Button>
                                        {uploadedLink && <Button type="button" onClick={handleExecute}>Insert</Button>}
                                    </div>

                                </div>
                            );
                        },
                        execute: (state, api) => {
                            if (uploadedLink) api.replaceSelection(`<img width="100%" src="${uploadedLink}"/>`);

                        },
                        buttonProps: { 'aria-label': 'Insert Image' }
                    }),
                    commands.divider,
                    commands.bold,
                    commands.hr, commands.italic, commands.divider,
                    commands.codeEdit, commands.codeLive,
                    commands.codePreview,
                    commands.divider,
                    commands.fullscreen,
                ]}
            />
        </>
    );
}
