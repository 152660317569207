
import React, { useState, createContext, useEffect, useContext } from "react"
import { useHistory } from 'react-router-dom'
import useFetch from "utils/useFetch"
import config from 'config';
import { AuthContext } from "context/authContext"
import axios from 'axios'
import qs from 'qs'
import useTokens from 'utils/useTokens'

const defaultContext = {
    faq: [],
    error: null,
    success: null,
    loading: false,
    getFaq: () => {
        throw new Error('getFaq() not implemented')
    },
    searchFaqs: () => {
        throw new Error('searchFaqs() not implemented')
    },
    updateFaq: () => {
        throw new Error('updateFaq() not implemented')
    },
}


export const FaqContext = createContext(defaultContext)

export const FaqProvider = ({ children }) => {

    const history = useHistory()

    const { getTokens } = useTokens()
    const [faqs, setFaqs] = useState([])
    const [faq, setFaq] = useState(null)
    const [error, setError] = useState()

    const [success, setSuccess] = useState()

    const [loading, setLoading] = useState()



    useEffect(() => {
        const timer = setTimeout(() => {
            setError()
        }, 5000);

        return () => clearTimeout(timer);
    }, [error]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess()
        }, 5000);

        return () => clearTimeout(timer);
    }, [success]);

    const { fetchData } = useFetch()

    const getFaq = async (faqId) => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token
        if (token) {
            setLoading(true)
            try {
                const res = await fetchData(
                    `${config.serverURL}/api/faq/${faqId}`,
                    "GET",
                    null,
                    true,
                    {},
                    token
                )
                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        setFaq(data)
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }

    const searchFaqs = async (query) => {

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            setLoading(true)

            try {
                const myHeaders = new Headers();

                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


                var urlencoded = new URLSearchParams();

                urlencoded.append("query", query);

                const res = await fetchData(
                    `${config.serverURL}/api/faq/search`,
                    "POST",
                    urlencoded,
                    true,
                    {},
                    token
                )

                setLoading(false)
                if (res.error) setError(res.error.message)
                else {
                    if (res.ok) {
                        const data = await res.json()
                        setFaqs(data)
                    }
                }
            }
            catch (err) {
                setError(err)
                console.error(err)
            }
        }
    }



    const handleUpdateFaq = async (faqId, { title, md, tags, type }) => {
        setLoading(true)

        const tokenObj = await getTokens()
        const token = tokenObj?.access?.token

        if (token) {
            var urlencoded = new URLSearchParams();

            urlencoded.append("title", title);
            urlencoded.append("md", md);
            // urlencoded.append("md", decodeURI(md));
            tags.forEach(tag => urlencoded.append("tags", tag))
            urlencoded.append("type", 'web');

            // var body = qs.stringify({
            //     'title': title,
            //     'md': decodeURI(md),
            //     'tags': tags,
            //     'type': 'web'
            // });

            // const body = {
            //     method: faqId ? 'patch' : 'post',
            //     url: faqId ? `${config.serverURL}/api/faq/${faqId}` : `${config.serverURL}/api/faq`,
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     },
            //     data: data
            // };

            try {
                const res = await fetchData(
                    faqId ? `${config.serverURL}/api/faq/${faqId}` : `${config.serverURL}/api/faq`,
                    faqId ? 'PATCH' : 'POST',
                    urlencoded,
                    true,
                    {},
                    token
                )
                // const res = await axios(body)
                setLoading(false)
                const data = await res.json()

                if (res.ok) {



                    setSuccess('Faq update successful')
                    history.push(`/faq/${data.id}`)
                }
                else {
                    setError(data.message)
                }
            } catch (err) {
                console.error(err)
            }
        }
    }
    return (
        <FaqContext.Provider
            value={{
                faqs,
                faq,
                setFaq,
                error,
                success,
                loading,
                getFaq,
                searchFaqs,
                handleUpdateFaq,
            }}
        >
            {children}
        </FaqContext.Provider>
    )
}


