import React, { useContext, useMemo, Suspense, useEffect } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from "utils/link"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { SignInForm, SignUpForm, ResetPassword, ForgotPassword } from 'components/auth';
import { Spinner, Label } from 'components/common';
import { AuthContext } from "context/authContext"
import { StyledHeading } from 'components/styles/Docs';
import { News } from 'components/news'
import Three from 'components/Three';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;

  @media only screen and (max-width: 767px) {
    display: block;
      padding-left: 2px;
      padding-right: 2px;

  }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    tree: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    paper: {
        height: '100%',
        margin: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spacer: {
        height: theme.spacing(4),
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    title: {
        fontFamily: 'Alenia',
    },

}));


export const LandingPage = () => {
    let location = useLocation();
    let history = useHistory();
    const classes = useStyles();
    const { isAuthenticated, loading } = useContext(AuthContext)

    useEffect(() => {
        let { from } = location.state || { from: { pathname: "/" } };

        if (isAuthenticated) history.replace(from);

    }, [isAuthenticated])

    const alternative = useMemo(() => {
        if (location.pathname.includes('/login')) {
            return (
                <>
                    <SignInForm />
                    <Grid container>
                        <Grid item xs={6}>
                            <Label color="secondary" style={{ textAlign: 'center' }}><Link to="/forgotpassword">Forgot password?</Link></Label>
                        </Grid>
                        <Grid item xs={6}>

                            <Label color="inherit" style={{ textAlign: 'center' }}><Link to="/register"> Don't have an account? Sign Up</Link></Label>

                        </Grid>
                    </Grid>

                </>

            );

        }
        else if (location.pathname.includes('/register')) {
            const email = new URLSearchParams(location.search).get("email") || "";
            return (
                <>
                    <SignUpForm email={email} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Label color="secondary" style={{ textAlign: 'center' }}><Link to="/login"> Already have an accout? Sign in!</Link></Label>
                        </Grid>
                    </Grid>
                </>

            );
        }
        else if (location.pathname.includes('/resetpassword')) {
            const token = new URLSearchParams(location.search).get("token") || "";
            return (
                <>
                    <ResetPassword token={token} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Label color="secondary" style={{ textAlign: 'center' }}><Link to="/login"> Back to sign in</Link></Label>
                        </Grid>
                    </Grid>
                </>
            );
        }
        else if (location.pathname.includes('/forgotpassword')) {
            return (

                <>
                    <ForgotPassword />
                    <Grid container>
                        <Grid item xs={12}>
                            <Label color="secondary" style={{ textAlign: 'center' }}><Link to="/login"> Back to sign in</Link></Label>
                        </Grid>
                    </Grid>
                </>

            );
        }
        else {
            return (

                <Grid container >
                    <Typography align="justify" variant="h6">Welcome to Code Academy Berlin's Learning Management System</Typography>
                    <Typography align="justify" variant="subtitle1">This platform's access is reserved to students of the academy and recruiting partners. </Typography>
                    <Typography align="justify" variant="subtitle1">If you are looking for more information about us, visit our commercial     <span align="center" variant="h2" color="secondary"><Link color="primary" to="https://www.codeacademyberlin.com"> website</Link></span></Typography>




                    <Grid item xs={6}>
                        <Label color="secondary" style={{ textAlign: 'center' }}><Link color="primary" to="/login"> To Sign In</Link></Label>
                    </Grid>
                    <Grid item xs={6}>
                        <Label color="secondary" style={{ textAlign: 'center' }}><Link color="primary" to="/register"> To Sign Up</Link></Label>
                    </Grid>
                </Grid>


            );
        }
    }, [location.pathname]);
    return isAuthenticated ?
        <Container>
            <StyledHeading> News</StyledHeading>
            <News />
        </Container>
        :
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>

                    <Typography color="primary" className={classes.title} component="h2" variant="h2" noWrap>
                        Code Academy Berlin
                    </Typography>
                    <Spinner size="100px" loading={loading} />
                    <Typography className={classes.title} color="primary" component="h2" variant="h5">
                        LMS
          </Typography>
                    <div className={classes.spacer}></div>

                    {alternative}
                </div>
            </Grid>
            <Grid className={classes.tree} item xs={false} sm={4} md={7} >
                <Suspense fallback={null}>
                    <Three />
                </Suspense>
            </Grid>
            {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}


        </Grid>
}
