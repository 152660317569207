import styled from 'styled-components';
import MaterialContainer from '@material-ui/core/Container';


export const Container = styled(MaterialContainer)`
padding-bottom: 30px
  ${'' /* display: flex;
  justify-content: center;
  align-items: center; */}
`;
